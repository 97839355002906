@import "../../../../assets/sass/mixins.scss";
.page-history-operation-search-options {
  &__body {
    display: none;
  }
  &__body--open {
    padding-top: var(--space-3m);
    display: block;
  }
  &__form {
    background-color: var(--background-secondary);
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;

    @include screen(md) {
    }
  }
  &__form-body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-3s);
    padding: var(--space-2s) var(--space-3s);

    @include screen(md) {
      row-gap: var(--space-3m);
      padding: var(--space-m) var(--space-4m);
    }
  }
  &__form-item {
    display: flex;
    flex-direction: column;
  }
  &__form-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--space-3m);

    & > * {
      flex: 1 1 100%;

      @include screen(md) {
        justify-content: flex-end;
        flex: 1 1 calc(50% - var(--space-3m));
      }
    }
  }
  &__form-summa {
    display: flex;
    align-items: center;
    column-gap: var(--space-2xs);
    justify-content: flex-start;

    .input {
      @include screen(lg) {
        flex: 0 1 180px;
      }
    }
  }
  &__form-item-chips {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-3m);
  }
  &__form-bottom {
    display: flex;
    justify-content: flex-start;
    padding: var(--space-2s) var(--space-3s);

    border-top: var(--border-width-base) solid var(--border-color-base);
    @include screen(md) {
      justify-content: flex-end;
      padding: var(--space-3s) var(--space-4m);
    }
  }
}
// @include screen(md) {
// }
