@import "../../assets/sass/mixins.scss";
.history-table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-2xs);
  box-sizing: border-box;

  @include screen(lg) {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0;

    background-color: var(--history-table-bg-color);
    border-radius: var(--history-table-border-radius);
  }

  &__header {
    display: none;
    box-sizing: border-box;
    @include screen(lg) {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: var(--history-table-header-height);
      padding-top: var(--space-2xs);
      padding-bottom: var(--space-2xs);
      padding-left: var(--space-4m);
      padding-right: var(--space-4m);

      border-bottom: var(--history-table-border-width) solid var(--history-table-border-color);
    }
  }
  &__item {
    display: flex;
    flex: 1 1 100%;

    @include screen(md) {
      flex: 1 1 calc(50% - var(--space-2xs));
    }

    @include screen(lg) {
      flex: auto;
      flex-direction: row;

      border-bottom: var(--history-table-border-width) solid var(--history-table-border-color);
    }

    &:last-child {
      border: none;
    }
  }
  &__item-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
    padding: var(--space-3s);

    @include screen(lg) {
      align-items: flex-start;
      flex-direction: row;
      row-gap: 0;
      padding: 0;
    }
  }
  &__header-item,
  &__item-list-item {
    display: flex;
    justify-content: space-between;
    column-gap: var(--space-xs);
    box-sizing: border-box;

    @include screen(lg) {
      padding-right: var(--space-2xs);
    }
  }
  &__header-item:first-child,
  &__item-list-item:first-child {
    @include screen(lg) {
      flex: 0 0 19.64%;
    }
  }
  &__header-item:nth-child(2),
  &__item-list-item:nth-child(2) {
    @include screen(lg) {
      flex: 0 0 39.67%;
    }
  }
  &__header-item:nth-child(3),
  &__item-list-item:nth-child(3) {
    @include screen(lg) {
      flex: 0 0 24%;
    }
  }
  &__header-item:last-child,
  &__item-list-item:last-child {
    order: 4;
    flex: 1 1 auto;
    padding-right: 0;
  }
  &__item-list-item:first-child {
    order: 1;
    margin-bottom: var(--space-2xs);

    @include screen(lg) {
      margin-bottom: 0;
    }
  }

  &__item-list-item:nth-child(2) {
    order: 3;

    @include screen(lg) {
      order: 2;
    }
  }

  &__item-list-item:nth-child(3) {
    order: 4;

    @include screen(lg) {
      order: 3;
    }
  }
  &__item-list-item:last-child {
    order: 2;

    @include screen(lg) {
      justify-content: flex-end;
      order: 4;
    }
  }

  &__item-list-item:last-child {
    text-align: right;

    @include screen(lg) {
      justify-content: flex-start;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    row-gap: var(--space-xs);

    @include screen(lg) {
      flex: 0 1 auto;
    }
  }
  &__info-item {
    display: inline-flex;
    justify-content: space-between;
    column-gap: var(--space-xs);

    @include screen(lg) {
      flex-direction: row;
      justify-content: flex-start;
      column-gap: var(--space-2xs);
    }

    & > .text:last-child {
      text-align: right;

      @include screen(lg) {
        text-align: left;
      }
    }
  }
  &__info-button {
    padding-top: var(--space-2s);

    .btn .icon {
      transform: rotate(-90deg);
    }
  }
  &__info-button--desctop {
    display: none;

    @include screen(lg) {
      display: block;
    }
  }
  &__info-button--mob {
    padding: var(--space-2s) var(--space-2s);

    border-top: var(--history-table-border-width) solid var(--history-table-border-color);
    @include screen(lg) {
      display: none;
    }
  }
  &__item-title {
    @include screen(lg) {
      display: none;
    }
  }

  .operation-state {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    border-radius: var(--history-table-border-radius);

    @include screen(lg) {
      padding-left: var(--space-4m);
      padding-right: var(--space-4m);
      padding-top: var(--space-3s);
      padding-bottom: var(--space-m);

      border-radius: 0;
    }
  }
}
