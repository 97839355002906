.btn--danger-secondary {
  color: var(--button-danger-secondary-color);

  background: var(--button-danger-secondary-bg-color);
  border-color: var(--button-danger-secondary-border-color);

  .icon {
    fill: #f84545;
  }

  &:hover {
    color: var(--button-danger-secondary-hover-color);

    background: var(--button-danger-secondary-hover-bg-color);
    border-color: var(--button-danger-secondary-hover-bg-color);

    .icon {
      fill: var(--button-danger-secondary-hover-icon-fill);
    }
  }
  &:active {
    color: var(--button-danger-secondary-active-color);

    background: var(--button-danger-secondary-active-bg-color);
    border-color: var(--button-danger-secondary-active-bg-color);
    opacity: var(--button-danger-secondary-active-opacity);

    .icon {
      fill: var(--button-danger-secondary-active-icon-fill);
    }
  }
  &:disabled {
    color: var(--button-danger-secondary-disabled-color);

    background: var(--button-danger-secondary-disabled-bg-color);
    border-color: var(--button-danger-secondary-disabled-border-color);

    .icon {
      fill: var(--button-danger-secondary-disabled-icon-fill);
    }
  }
}
