@import "../../../assets/sass/mixins.scss";
.status {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include screen(lg) {
    padding: var(--status-padding);
    border-radius: var(--status-border-radius);
  }

  &--done {
    color: var(--status-color-done);

    @include screen(lg) {
      background-color: var(--status-background-color-done);
    }
  }
  &--processing {
    color: var(--status-color-processing);

    @include screen(lg) {
      background-color: var(--status-background-color-processing);
    }
  }
  &--cancel {
    color: var(--status-color-cancel);

    @include screen(lg) {
      background-color: var(--status-background-color-cancel);
    }
  }
  &--checked {
    color: var(--status-color-checked);

    @include screen(lg) {
      background-color: var(--status-background-color-checked);
    }
  }
}
