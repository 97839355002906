.toggle {
  display: inline-flex;
  column-gap: var(--space-2s);
  align-items: center;

  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &__label {
    display: flex;
    box-sizing: border-box;

    background-color: transparent;

    cursor: pointer;
  }
  &__input[type="checkbox"] {
    display: none;
  }
  &__input[type="checkbox"] + .toggle__visual {
    position: relative;
    top: 0;
    right: 0;

    box-sizing: border-box;
    display: inline-flex;
    width: var(--toggle-visual-width);
    height: var(--toggle-visual-height);

    background-color: var(--toggle-background-color);
    border-radius: var(--toggle-border-radius);

    transition: all var(--transition) ease 0s;

    &:hover {
      background-color: var(--toggle-background-color-hover);
    }
  }
  &__input[type="checkbox"] + .toggle__visual::before {
    position: absolute;
    top: 50%;
    left: var(--toggle-visual-before-position-x);

    display: block;
    width: var(--toggle-width-before);
    height: var(--toggle-height-before);

    content: "";
    background-color: var(--toggle-background-color-before);
    border-radius: var(--toggle-border-radius-before);
    transform: translateY(-50%);

    transition: all var(--transition) ease 0s;
  }
  &__input[type="checkbox"]:checked + .toggle__visual::before {
    left: auto;
    right: var(--toggle-visual-before-position-x);
  }
  &__input[type="checkbox"]:checked + .toggle__visual {
    background-color: var(--toggle-background-color-checked);
    &:hover {
      background-color: var(--toggle-background-color-checked-hover);
    }
  }
  &__input[type="checkbox"]:disabled + .toggle__visual {
    background-color: var(--toggle-background-color-disabled);
    cursor: not-allowed;
    &:hover {
      background-color: var(--toggle-background-color-disabled);
    }
  }
  &__content {
    display: inline-flex;
    align-self: flex-start;
  }
}
