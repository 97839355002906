@import "../../../assets/sass/mixins.scss";
.vertical-offset {
  display: flex;
  flex-direction: column;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .vertical-offset-#{$infix}-xs {
      row-gap: var(--space-xs);
    }
    .vertical-offset-#{$infix}-2xs {
      row-gap: var(--space-2xs);
    }
    .vertical-offset-#{$infix}-s {
      row-gap: var(--space-s);
    }
    .vertical-offset-#{$infix}-2s {
      row-gap: var(--space-2s);
    }
    .vertical-offset-#{$infix}-3s {
      row-gap: var(--space-3s);
    }
    .vertical-offset-#{$infix}-m {
      row-gap: var(--space-m);
    }
    .vertical-offset-#{$infix}-2m {
      row-gap: var(--space-2m);
    }
    .vertical-offset-#{$infix}-3m {
      row-gap: var(--space-3m);
    }
    .vertical-offset-#{$infix}-4m {
      row-gap: var(--space-4m);
    }
    .vertical-offset-#{$infix}-l {
      row-gap: var(--space-l);
    }
    .vertical-offset-#{$infix}-2l {
      row-gap: var(--space-2l);
    }
    .vertical-offset-#{$infix}-xl {
      row-gap: var(--space-xl);
    }
  }
}
