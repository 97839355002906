@import "../../assets/sass/mixins.scss";
.sidebar {
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: calc(-1 * var(--sidebar-width));
  z-index: 1000;

  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  flex: 0 0 var(--sidebar-width);
  width: var(--sidebar-width);
  min-height: calc(100vh - var(--header-height));

  border-right: var(--sidebar-border-width) solid var(--sidebar-border-color);
  background-color: var(--sidebar-background-color);

  transition: all var(--transition) ease 0s;

  box-shadow: var(--shadow-one);

  @include screen(xl) {
    position: static;
    z-index: 0;

    flex: 0 0 var(--sidebar-width-xl);
    width: var(--sidebar-width-xl);

    box-shadow: none;
  }
  &__body {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;
    min-height: 100%;
    padding: var(--sidebar-body-padding);

    scrollbar-width: thin;
    scrollbar-color: var(--background-base) var(--background-three);

    &::-webkit-scrollbar {
      width: 3px;
      height: 80%;
    }
    &::-webkit-scrollbar-track {
      background: var(--background-three);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--background-base);
      border: 1px solid var(--background-base);
    }

    @include screen(xl) {
      padding: var(--sidebar-body-padding-xl);
    }
  }
  &__top {
    .menu__links {
      position: relative;

      .counter-round {
        position: absolute;
        top: -12px;
        right: -28px;
      }
    }
  }
  &__bottom {
    padding-top: var(--space-m);
    .text {
      font-weight: 400;
    }
  }
  &--open {
    left: 0;
  }
}
