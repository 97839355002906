@import "../../../../assets/sass/mixins.scss";
.chat-list {
  &__title-date {
    margin-bottom: var(--space-2s);

    @include screen(md) {
      margin-bottom: var(--space-3m);
    }

    > .text {
      text-align: center;
      font-weight: 400;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2xs);
    padding: 0;
    margin: 0;

    list-style: none;

    @include screen(md) {
      row-gap: var(--space-2s);
    }
  }
  &__item {
    width: auto;
    min-width: 30%;
    padding: 12px 16px;
    margin-left: 0;
    margin-right: auto;
    box-sizing: border-box;

    background-color: var(--chat-list-bg-color);
  }
  &__item--support {
    max-width: 95%;

    border-radius: var(--chat-list-border-radius) var(--chat-list-border-radius) var(--chat-list-border-radius) 0px;

    @include screen(md) {
      min-width: 30%;
      max-width: 74%;
    }
  }
  &__item--user {
    max-width: 75%;
    margin-right: 0;
    margin-left: auto;
    border-radius: var(--chat-list-border-radius) var(--chat-list-border-radius) 0px var(--chat-list-border-radius);

    @include screen(md) {
      min-width: 30%;
      max-width: 90%;
    }
  }
  &__date {
    margin-bottom: var(--space-xs);
  }
  &__message {
    margin-bottom: var(--space-xs);

    .text {
      font-weight: 400;
    }

    a {
      cursor: pointer;
      font-weight: 400;
      text-decoration: underline;
    }
  }
  &__files-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--space-2xs);
    margin-top: var(--space-s);
    padding-top: var(--space-s);

    border-top: var(--border-width-base) solid var(--border-color-base);

    .btn {
      .text {
        display: inline-block;
        max-width: 100px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @include screen(sm) {
          max-width: 200px;
        }
        @include screen(md) {
          max-width: 300px;
        }
        @include screen(lg) {
          max-width: 400px;
        }
      }
    }
  }
  &__time {
    text-align: right;
  }
}
