.dropdown-quantity {
  position: relative;
  z-index: 10;
  
  display: flex;
  align-items: center;
  column-gap: var(--dropdown-quantity-column-gap);

  .text {
    white-space: nowrap;
		word-break: keep-all; 
  }
  &__control {
    align-items: center;
    width: 100%;
    min-width: var(--dropdown-quantity-control-min-width);
    box-sizing: border-box;
    padding: var(--dropdown-quantity-control-pd);
    height: var(--dropdown-quantity-control-height) !important;

    cursor: pointer !important;
    border-radius: var(--dropdown-quantity-border-radius) !important;
    border-color: var(--dropdown-quantity-border-color) !important;
    box-shadow: none !important;

    &:hover {
      border-color: var(--color-button-text-secondary) !important;
    }
  }
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;

    color: var(--dropdown-quantity-text-color) !important;
    border: none !important;
  }
  &__value-container {
    padding: 0 !important;

    font-size: var(--dropdown-quantity-font-size);
    line-height: var(--dropdown-quantity-line-height);
    color: var(--dropdown-quantity-text-color) !important;
  }
  &__single-value {
    display: flex;
    margin: 0 !important;
  }
  &__indicator {
    padding: 0 0 0 var(--dropdown-quantity-indicator-pd) !important;

    cursor: pointer !important;

    .icon {
      fill:  var(--dropdown-quantity-indicator-icon-fill);
      transition: all var(--transition) ease 0s;

      &:hover {
        fill: var(--dropdown-quantity-indicator-icon-fill-hover);
      }
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    margin: 0 !important;

    border: var(--dropdown-quantity-border-width) solid var(--dropdown-quantity-border-color-active) !important;
    border-radius: 0  0 var(--dropdown-quantity-border-radius) var(--dropdown-quantity-border-radius) !important;
    border-top: none !important;
    box-shadow: none !important;
  }
  &__menu-list {
    min-height: var(--dropdown-quantity-menu-list-min-height);
    max-height: var(--dropdown-quantity-menu-list-max-height) !important;
    padding: 0 !important;
  }
  &__option {
    position: relative;

    display: flex !important;
    padding: var(--dropdown-quantity-option-pd) !important;

    font-size: var(--dropdown-quantity-font-size) !important;
    line-height: var(--dropdown-quantity-line-height) !important;
    color: var(--dropdown-quantity-text-color) !important;

    cursor: pointer !important;
    border-radius: 0 !important;

    &:hover {
      background-color: var( --langs-option-bg-color-hover) !important;
    }
    &:last-child {
      border-radius: 0 0 var(--dropdown-quantity-border-radius) var(--dropdown-quantity-border-radius) !important;
    }
  }
  &__option--is-selected {
    background-color: transparent !important;

    &::before {
      position: absolute;
      top: 48%;
      right: 8px;

      display: flex;
      width: var(--langs-option-check-size);
      height: var(--langs-option-check-size);

      content: "";
      background-image: url(../../assets/icon/icon--check-grey.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform: translateY(-50%);
    }
  }
  &__control--menu-is-open {
    border-radius: var(--dropdown-quantity-border-radius) var(--dropdown-quantity-border-radius) 0 0 !important;
    border-bottom: none !important;
    border-color: var(--dropdown-quantity-border-color-active) !important;

    background-color: var(--dropdown-quantity-bg-color) !important;

    &:hover {
      border-color: var(--dropdown-quantity-border-color-active) !important;
    }
  }
  &__option--is-focused {
    background-color: transparent !important;
  }
  .icon-img {
    border-radius: 50%;
  }
}
