@import "../../assets/sass/mixins.scss";
.chat {
  position: relative;

  align-self: start;
  padding: var(--space-4m) var(--space-m);

  background-color: var(--chat-bg-color);
  border-radius: var(--chat-border-radius);
  width: 100%;

  @include screen(md) {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    padding: 0;
  }
  &__left {
    position: relative;
    top: 30px;

    margin-left: -20px;
    margin-right: -20px;

    background-color: var(--chat-bg-color);

    @include screen(md) {
      top: 0;

      padding-top: var(--space-2xs);
      padding-bottom: var(--space-l);
      margin-right: 0;
      margin-left: 0;
      flex: 0 0 var(--chat-left-width);

      border-right: var(--chat-border-width) solid var(--chat-border-color);
      border-radius: var(--chat-border-radius) 0 0 var(--chat-border-radius);
    }
  }
  &__title {
    margin-bottom: var(--space-3s);
    padding: var(--space-3s) 0 0 var(--space-3s);

    @include screen(md) {
      display: none;
    }
  }
  &__body {
    position: absolute;
    right: 0;
    z-index: 10;
    top: 0;

    display: flex;
    width: 100%;
    overflow: hidden;

    background-color: var(--chat-bg-color);
    border-radius: var(--chat-border-radius);

    @include screen(md) {
      position: static;
    }

    transition: all var(--transition) ease 0s;
  }
  &__body-wrapper {
    display: flex;
    flex-direction: column;
    height: var(--chat-body-body-wrapper-height);
    height: calc(100vh - var(--chat-body-body-wrapper-height));
    overflow-y: auto;
    flex: 1 1 auto;

    scrollbar-width: thin;
    scrollbar-color: var(--color-blue-mat) var(--color-button-border-default);

    &::-webkit-scrollbar {
      width: 3px;
      height: 80%;
    }
    &::-webkit-scrollbar-track {
      background: var(--color-button-border-default);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-blue-mat);
      border: 1px solid var(--color-blue-mat);
    }

    @include screen(md) {
      justify-content: flex-end;
      flex: 1 1 auto;
      width: auto;
      padding: var(--space-m);
      row-gap: var(--space-4m);
      height: var(--chat-body-body-wrapper-height-md);
    }
  }
  &__body-button {
    padding: var(--space-3s);

    border-bottom: var(--chat-border-width) solid var(--chat-border-color);

    @include screen(md) {
      display: none;
    }
  }
  &__body-list {
    display: flex;
    flex-direction: column-reverse;
    flex: 1 1 auto;
    row-gap: var(--space-m);
    padding: var(--space-3s);
    margin-right: var(--space-xs);
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: var(--color-blue-mat) var(--color-button-border-default);

    &::-webkit-scrollbar {
      width: 3px;
      height: 80%;
    }
    &::-webkit-scrollbar-track {
      background: var(--color-button-border-default);
    }
    &::-webkit-scrollbar-thumb {
      background: var(--color-blue-mat);
      border: 1px solid var(--color-blue-mat);
    }

    @include screen(md) {
      height: 100%;
      min-height: auto;
      max-height: inherit;
      padding: 0;
      padding-right: var(--space-xs);
      margin-right: 0;
    }
  }
  &__actions {
    padding: var(--space-3s);

    border-top: var(--chat-border-width) solid var(--chat-border-color);

    @include screen(md) {
      padding: 0;

      border-top: none;
    }
  }
  &-list__files-link {
    display: block;
  }
}
.chat--short {
  .chat__body {
    position: static;
  }
  .chat__body-list {
    padding: 0;
    padding-right: var(--space-xs);
    margin-right: 0;

    @include screen(md) {
      height: 100%;
      min-height: auto;
      max-height: inherit;
      padding-right: var(--space-xs);
    }
  }
  .chat__body-wrapper {
    height: var(--chat-short-body-wrapper-height);
    row-gap: var(--space-2l);

    @include screen(md) {
      height: var(--chat-short-body-wrapper-height-md);
      row-gap: var(--space-4m);
    }
  }
  .chat__actions {
    padding: 0;

    border-top: none;
  }
  .chat-list__body {
    row-gap: var(--space-3m);

    @include screen(md) {
      row-gap: var(--space-2s);
    }
  }
}
