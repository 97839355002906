@import "../../../assets/sass/mixins.scss";
.btn {
  position: relative;

  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  column-gap: var(--button-column-gap);
  justify-content: center;
  height: var(--button-height);
  padding: var(--button-padding);
  margin: 0;

  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  font-weight: 500;
  color: var(--button-color);
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
  word-break: keep-all;

  cursor: pointer;
  user-select: none;
  background: var(--button-background);
  border: var(--button-border-width) solid var(--button-border-color);
  border-radius: var(--button-border-radius);
  outline: none;

  transition: all var(--transition) ease;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  & .icon {
    transition: all var(--transition) ease;
    fill: var(--button-icon-fill);
  }
  &:hover {
    background: var(--button-background-color-hover);
    border: var(--button-border-width) solid var(--button-border-color-hover);
  }
  &:active {
    background: var(--button-background-color-active);
    border: var(--button-border-width) solid var(--button-border-color-active);
  }
  &:disabled {
    color: var(--button-color-disabled);
    cursor: not-allowed;
    background: var(--button-background-color-disabled);
    border: var(--button-border-width) solid var(--button-border-color-disabled);

    & .icon {
      fill: var(--button-icon-fill-disabled);
    }
  }
}

@import "--outline/--outline.scss";
@import "--icon/--icon.scss";
@import "--flat/--flat.scss";
@import "--flat-secondary/--flat-secondary.scss";
@import "--secondary/--secondary.scss";
@import "--danger/--danger.scss";
@import "--danger-secondary/--danger-secondary.scss";
@import "--icon-outline/--icon-outline.scss";
@import "--size/--size.scss";
