@import "../../assets/sass/mixins.scss";
.llst-form {
  &__title {
    margin-bottom: var(--space-4m);
  }
  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: var(--space-3m);

    & > div {
      flex: 1 1 100%;

      @include screen(md) {
        flex: 1 1 calc(50% - var(--space-3m));
      }
    }
  }
}