.btn--danger {
  color: var(--button-danger-color);

  background: var(--button-danger-bg);
  border: var(--button-border-width) solid var(--button-danger-border-color);

  &:hover {
    background: var(--button-danger-bg-hover);
    border: var(--button-border-width) solid var(--button-danger-border-color-hover);
    opacity: var(--button-danger-opacity-hover);
  }
  &:active {
    background: var(--button-danger-bg-active);
    border: var(--button-border-width) solid var(--button-danger-border-color-active);
  }
  &:disabled {
    background: var(--button-background-color-disabled);
    border: var(--button-border-width) solid var(--button-border-color-disabled);
  }
}
