@import "../../assets/sass/mixins.scss";
.modal-body {
  width: 100%;

  &__content {
    flex: 1 1 auto;
    padding-left: var(--modal-pd-left);
    padding-right: var(--modal-pd-right);
    padding-bottom: var(--space-2xs);

    @include screen(lg) {
      padding-left: var(--modal-pd-left-md);
      padding-right: var(--modal-pd-right-md);
      padding-bottom: var(--space-3s);
    }
  }
}
