.calendar-custom {
  &__label {
    display: inline-flex;
    min-height: var(--calendar-custom-label-height);
    box-sizing: border-box;
    padding-bottom: var(--space-xs);
  }
  &__day {
    display: flex;
    align-items: center;
    justify-content: center;

    height: var(--calendar-custom-day-height);

    font-family: var(--font-main);
    font-weight: 400;
    font-size: var(--calendar-custom-font-size);
    line-height: var(--calendar-custom-line-height);

    transition: all var(--transition) ease 0s;
  }

  &__calendar {
    width: 100%;
    max-width: var(--calendar-custom-calendar-width);

    font-family: var(--font-main);
    font-weight: 400;
    font-size: var(--calendar-custom-font-size);
    line-height: var(--calendar-custom-line-height);

    border: none;
    background-color: transparent;

    .react-calendar__navigation__label {
      font-family: var(--font-main);
      font-weight: 400;
      font-size: var(--calendar-custom-font-size);
      line-height: var(--calendar-custom-line-height);
    }
    .react-calendar__navigation {
      height: calc(var(--calendar-custom-navigation-height) + var(--calendar-custom-navigation-pb));
      box-sizing: border-box;
      padding-bottom: var(--calendar-custom-navigation-pb);
      margin-bottom: 0;

      .react-calendar__navigation__arrow,
      .react-calendar__navigation__label {
        &:hover,
        &:focus {
          background-color: var(--calendar-custom-navigation-arrow-hover);

          .icon {
            fill: var(--calendar-custom-navigation-arrow-icon-fill-hover);
          }
        }
      }
    }
    .react-calendar__navigation__arrow {
      display: flex;
      align-items: center;
      justify-content: center;

      transition: all var(--transition) ease 0s;
      cursor: pointer;

      .icon {
        fill: var(--calendar-custom-navigation-arrow-icon-fill);

        transition: all var(--transition) ease 0s;
      }
    }
    .react-calendar__navigation__prev-button {
      border: var(--calendar-custom-navigation-border-width) solid var(--calendar-custom-navigation-border-color);
      border-radius: var(--calendar-custom-navigation-border-radius) 0px 0px
        var(--calendar-custom-navigation-border-radius);
    }
    .react-calendar__navigation__label {
      text-transform: uppercase;

      border: var(--calendar-custom-navigation-border-width) solid var(--calendar-custom-navigation-border-color);
      border-left: none;
      border-right: none;
    }
    .react-calendar__navigation__next-button {
      border: var(--calendar-custom-navigation-border-width) solid var(--calendar-custom-navigation-border-color);
      border-radius: 0px var(--calendar-custom-navigation-border-radius) var(--calendar-custom-navigation-border-radius)
        0;
      cursor: pointer;
    }
    .react-calendar__viewContainer {
      padding: var(--space-3s);

      background: var(--calendar-custom-view-container-bg-color);
      border: var(--calendar-custom-navigation-border-width) solid var(--calendar-custom-navigation-border-color);
      box-sizing: border-box;
      box-shadow: var(--shadow-one);
      border-radius: (--calendar-custom-navigation-border-radius);
    }
    .react-calendar__month-view__weekdays__weekday {
      box-sizing: border-box;
      padding: var(--space-xs);

      font-family: var(--font-main);
      font-weight: 400;
      font-size: var(--calendar-custom-font-size);
      line-height: var(--calendar-custom-line-height);
      color: var(--calendar-custom-month-view-weekdays-weekday);
    }
    abbr[title] {
      text-decoration: none;
    }
    .react-calendar__month-view__days__day--weekend {
      color: var(--calendar-custom-month-view-days-day-weekend);
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: var(--calendar-custom-month-view-days-day-neighboringMonth);
    }
    .react-calendar__month-view__days__day--neighboringMonth.react-calendar__month-view__days__day--weekend {
      color: var(--calendar-custom-month-view-days-day-neighboringMonth-disable);
    }
    .react-calendar__tile--active,
    .react-calendar__tile--now {
      position: relative;

      color: var(--calendar-custom-tile-active-color);

      background-color: transparent;

      &::before {
        position: absolute;
        bottom: 4px;
        left: 50%;

        display: block;
        width: 60%;
        height: 2px;

        content: "";
        background: var(--calendar-custom-tile-bg-color);
        transform: translateX(-50%);
      }
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background: var(--calendar-custom-tile-bg-color);
      border-radius: var(--calendar-custom-tile-border-radius);
      color: var(--calendar-custom-tile-color);
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      opacity: 0.7;
    }
    .react-calendar__tile--rangeEnd {
      background: var(--calendar-custom-tile-bg-color);
      border-radius: var(--calendar-custom-tile-border-radius);
      color: var(--calendar-custom-tile-color);
      clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 15% 100%, 0% 50%);

      &::before {
        display: none;
      }
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
    .react-calendar__tile--rangeStart {
      background: var(--calendar-custom-tile-bg-color);
      border-radius: var(--calendar-custom-tile-border-radius);
      color: var(--calendar-custom-tile-color);
      clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);

      &::before {
        display: none;
      }
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
    .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
      clip-path: none;
    }

    .react-calendar__decade-view__years,
    .react-calendar__century-view__decades {
      row-gap: 20px;
    }
    .react-calendar__year-view__months {
      padding: 0;
      row-gap: var(--space-s);
    }
    .react-calendar__decade-view__years__year,
    .react-calendar__century-view__decades__decade,
    .react-calendar__year-view__months__month {
      box-sizing: border-box;
      height: var(--calendar-custom-decade-century-year-height);
      padding: var(--space-2xs) var(--space-2xs);
    }
    .react-calendar__century-view__decades__decade {
      height: var(--calendar-custom-century-view-decades-decad-height);
      text-align: left;
    }
    .react-calendar__decade-view__years__year,
    .react-calendar__year-view__months__month {
      background-color: transparent;

      color: var(--calendar-custom-decade-year-view-color);

      &:hover {
        color: var(--calendar-custom-decade-year-view-color-hover) !important;

        background-color: transparent !important;
      }
    }
    .react-calendar__tile.react-calendar__tile--hasActive,
    .react-calendar__century-view__decades__decade.react-calendar__tile--hasActive {
      position: relative;

      &::before {
        position: absolute;
        bottom: 2px;
        left: 50%;

        display: block;
        width: 60%;
        height: 2px;

        content: "";
        background: var(--calendar-custom-tile-bg-color);
        transform: translateX(-50%);
      }
    }
  }
}
