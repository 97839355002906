@import "../../../../assets/sass/mixins.scss";
.sidebar-list {
  &__title {
    margin-bottom: var(--space-2s);
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    list-style: none;
    &--xs {
      gap: var(--space-xs);
    }
    &--2xs {
      gap: var(--space-2xs);
    }
    &--s {
      gap: var(--space-s);
    }
    &--2s {
      gap: var(--space-2s);
    }
    &--3s {
      gap: var(--space-3s);
    }
    &--m {
      gap: var(--space-m);
    }
    &--2m {
      gap: var(--space-2m);
    }
    &--4m {
      gap: var(--space-3m);
    }
    &--4m {
      gap: var(--space-4m);
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--space-xs);

    &--reserves {
      .text:first-child {
        max-width: 120px;
        overflow: hidden;

        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 400;
      }
      .text:last-child {
        max-width: 100px;
        overflow: hidden;

        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &--my-accounts {
      .icon-text .text {
        max-width: 100px;
        overflow: hidden;

        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > .text {
        max-width: 100px;
        overflow: hidden;

        text-align: right;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  &__button {
    margin-top: var(--space-m);

    .btn .icon {
      transition: all var(--transition) ease 0s;
    }

    @include screen(lg) {
      margin-top: var(--space-3m);
    }
  }
  &__button--show {
    .icon {
      transform: rotate(180deg);
    }
  }
  &__content--heide {
    transition: all var(--transition) ease 0s;
  }
  &__content--show {
    overflow: auto;
  }
}
