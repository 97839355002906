@import "../../../../assets/sass/mixins.scss";
.page-reports-info {
  box-sizing: border-box;

  @include screen(md) {
    background-color: var(--background-secondary);
    border-radius: var(--border-radius-base);
  }
  &__top {
    margin-bottom: var(--space-m);

    @include screen(md) {
      padding: var(--space-m) var(--space-l);
      margin-bottom: 0;

      border-bottom: var(--border-width-base) solid var(--border-color-base);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-m);
    @include screen(md) {
      row-gap: var(--space-3m);
      padding: var(--space-m) var(--space-l);
    }
  }
}
