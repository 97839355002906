.operation-number-state {
  display: flex;
  align-items: center;
  column-gap: var(--space-2xs);

  .icon--arrow-long-bottom-down {
    fill: var(--operation-number-state-color-down);
  }
  .icon--arrow-long-bottom-up {
    fill: var(--operation-number-state-color-up);
  }
}
.operation-icon-type {
  display: flex;
  align-items: center;
  gap: 4px;

  &__icon {
    display: flex;
    flex: 0 0 24px;
  }
  .icon--arrow-long-bottom-down {
    fill: var(--operation-number-state-color-down);
  }
  .icon--arrow-long-bottom-up {
    fill: var(--operation-number-state-color-up);
  }
  .icon--obmen,
  .icon--na-obmen,
  .icon--s-exchange {
    fill: var(--operation-number-state-color-all-icon);
  }
}
