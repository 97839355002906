@import "../../../../assets/sass/mixins.scss";
.page-create-ad-form-sell-buy {
  &__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--space-m);

    &:not(:last-child) {
      margin-bottom: var(--space-3m);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2l);

    @include screen(md) {
      row-gap: var(--space-3m);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-2l);
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    column-gap: var(--space-m);
  }
}
