@import "../../assets/sass/mixins.scss";
.requisite {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: var(--space-2xs);
  padding: var(--space-2m) var(--space-3s) var(--space-4s) var(--space-3s);

  @include screen(lg) {
    align-items: center;
    flex-direction: row;
    column-gap: var(--space-xs);
    row-gap: 0;
    padding-left: var(--space-3s);
    padding-right: var(--space-3s);
    padding-top: 0;
    padding-bottom: 0;
    height: 74px;
  }

  &__name {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-name) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-name) - var(--space-xs));
    }
    .requisite-info__value {
      max-width: 100px;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include screen(sm) {
        max-width: 200px;
      }
      @include screen(lg) {
        max-width: 100%;
      }
    }
  }
  &__direction {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-direction) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-direction) - var(--space-xs));
    }
    .text--size-number-state {
      font-size: inherit;
      line-height: inherit;

      @include screen(lg) {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }
  &__currency {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-currency) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-currency) - var(--space-xs));
    }

    .requisite-info__value {
      max-width: 100px;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include screen(sm) {
        max-width: 200px;
      }
      @include screen(lg) {
        max-width: 100%;
      }
    }
  }
  &__payment-system {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-payment-system) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-payment-system) - var(--space-xs));
    }
    @include screen(xl) {
      width: calc(var(--requisite-name-width-payment-system-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-payment-system-xl) - var(--space-xs));
    }

    .requisite-info__value {
      max-width: 160px;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include screen(sm) {
        max-width: 200px;
      }
      @include screen(lg) {
        max-width: 100%;
      }
    }
  }
  &__applications-month {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-applications-month) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-applications-month) - var(--space-xs));
    }
    @include screen(xl) {
      width: calc(var(--requisite-name-width-applications-month-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-applications-month-xl) - var(--space-xs));
    }
  }
  &__turnover-month {
    @include screen(lg) {
      width: calc(var(--requisite-name-width-turnover-month) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-turnover-month) - var(--space-xs));
    }
    @include screen(xl) {
      width: calc(var(--requisite-name-width-turnover-month-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-turnover-month-xl) - var(--space-xs));
    }

    .requisite-info__value {
      .text {
        display: inline-flex;
        @include screen(xl) {
          flex-wrap: wrap;
        }
      }
    }
  }
  &__turnover-month-value {
    display: inline-block;
    max-width: 100px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  &__actions {
    width: var(--requisite-name-width-actions);
    flex: 1 1 var(--requisite-name-width-actions);
  }
}
.requisite-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--space-xs);

  @include screen(lg) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__name {
    @include screen(lg) {
      display: none;
    }

    .text {
      font-weight: normal;
    }
  }
  &__value {
    display: flex;

    .text {
      text-align: right;

      @include screen(lg) {
        text-align: left;
      }
    }
  }
}
.requisite-head {
  display: none;

  @include screen(lg) {
    display: flex;
    align-items: center;
    column-gap: var(--space-xs);
    height: 55px;
    padding-left: var(--space-3s);
    padding-right: var(--space-3s);

    background-color: var(--background-four);
    border-radius: 8px 8px 0px 0px;
  }

  &__name {
    width: calc(var(--requisite-name-width-name) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-name) - var(--space-xs));
  }
  &__direction {
    width: calc(var(--requisite-name-width-direction) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-direction) - var(--space-xs));
  }
  &__currency {
    width: calc(var(--requisite-name-width-currency) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-currency) - var(--space-xs));
  }
  &__payment-system {
    width: calc(var(--requisite-name-width-payment-system) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-payment-system) - var(--space-xs));

    @include screen(xl) {
      width: calc(var(--requisite-name-width-payment-system-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-payment-system-xl) - var(--space-xs));
    }
  }
  &__applications-month {
    width: calc(var(--requisite-name-width-applications-month) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-applications-month) - var(--space-xs));

    @include screen(xl) {
      width: calc(var(--requisite-name-width-applications-month-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-applications-month-xl) - var(--space-xs));
    }
  }
  &__turnover-month {
    width: calc(var(--requisite-name-width-turnover-month) - var(--space-xs));
    flex: 0 0 calc(var(--requisite-name-width-turnover-month) - var(--space-xs));

    @include screen(xl) {
      width: calc(var(--requisite-name-width-turnover-month-xl) - var(--space-xs));
      flex: 0 0 calc(var(--requisite-name-width-turnover-month-xl) - var(--space-xs));
    }
  }
  &__actions {
    width: var(--requisite-name-width-actions);
    flex: 1 1 var(--requisite-name-width-actions);
  }
}
.requisite-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-2s);

  @include screen(lg) {
    column-gap: var(--space-3m);
    row-gap: 0;

    border-radius: 0px 0px 8px 8px;
    background-color: var(--background-secondary);
  }
  .operation-state {
    width: 100%;

    border-radius: 8px;
    border-bottom: 1px solid #d9d9d9;

    @include screen(md) {
      width: calc(50% - var(--space-2s));
      flex: 1 1 calc(50% - var(--space-2s));
    }
    @include screen(lg) {
      width: 100%;
      flex: 1 1 100%;

      border-radius: 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 8px 8px;
    }
  }
}
.requisite-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: var(--space-2s);

  @include screen(lg) {
    column-gap: var(--space-4s);
  }
}
