@import "../../assets/sass/mixins.scss";
.history-table-operations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-2xs);
  row-gap: var(--space-4s);
  box-sizing: border-box;

  @include screen(lg) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &__item {
    background-color: var(--history-table-operations-bg-color);
    border-radius: var(--history-table-operations-border-radius);

    flex: 1 1 100%;

    @include screen(md) {
      flex: 1 1 calc(50% - var(--space-2xs));
    }
    @include screen(lg) {
      flex: auto;
    }
  }
  &__item-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: var(--space-s);
    padding: var(--history-table-operations-pd);

    border-bottom: var(--history-table-operations-border-width) solid var(--history-table-operations-border-color);

    @include screen(lg) {
      align-items: center;
      column-gap: var(--space-3m);
      padding: var(--history-table-operations-pd-lg);
    }
  }
  &__item-header-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1 1 auto;
    row-gap: var(--space-xs);
    column-gap: var(--space-3s);
  }
  &__item-body {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-2xs);
    padding: var(--history-table-operations-pd);

    @include screen(lg) {
      padding: var(--history-table-operations-pd-lg);
    }
  }
  &__item-body-item {
    display: flex;
    column-gap: var(--space-xs);
  }
  &__date-item {
    display: inline-flex;
    align-items: center;
    column-gap: var(--space-xs);

    @include screen(lg) {
      column-gap: var(--space-2xs);
    }
  }
  &__info-item {
    word-break: break-all;
  }
}
