@import "../../assets/sass/mixins.scss";
.logo {
  display: inline-flex;
  width: var(--logo-width-mob);

  text-decoration: none;
  transition: all var(--transition) ease 0s;

  @include screen(xl) {
    width: var(--logo-width);
  }

  &:hover {
    opacity: 0.4;
  }
  img {
    display: block;
    width: 100%;
  }
}
