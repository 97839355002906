@import "../../../assets/sass/mixins.scss";
.form-modal {
  display: flex;
  flex-direction: column;
  align-self: normal;
  justify-content: flex-end;
  width: 100%;

  &__body {
    flex: 1 1 auto;
    padding-left: var(--modal-pd-left);
    padding-right: var(--modal-pd-right);
    padding-bottom: var(--space-2xs);

    @include screen(lg) {
      padding-left: var(--modal-pd-left-md);
      padding-right: var(--modal-pd-right-md);
      padding-bottom: var(--space-3s);
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: var(--space-m);
    padding: var(--space-3m) var(--space-m);

    background-color: var(--color-disable-background);

    @include screen(lg) {
      padding: var(--space-3m) var(--space-xl);
    }
  }
}
