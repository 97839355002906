.operation-state {
  &--sale {
    background-color: var(--operation-state-bg-color-sale);
    border: 1px solid var(--operation-state-border-color-sale);
  }
  &--buy {
    background-color: var(--operation-state-bg-color-buy);
    border: 1px solid var(--operation-state-border-color-buy);
  }
  &--empty {
    background-color: var(--operation-state-bg-color-empty);
    border: 2px solid var(--operation-state-border-color-sale);
    
    .requisite-info .text {
      color: var(--operation-state-text-color-empty);
    }
  }
}
