@import "../../../assets/sass/mixins.scss";
.block-content {
  width: 100%;
  box-sizing: border-box;
  padding: var(--block-content-pd-mob);

  border-radius: var(--block-content-border-r);

  &:not(:last-child) {
    margin-bottom: var(--space-3m);
  }

  @include screen(lg) {
    padding: var(--block-content-pd-lg);
  }
  &--base {
    background-color: var(--block-content-bg-color-base);
  }
  &--secondary {
    background-color: var(--block-content-bg-color-secondary);
  }
  &--three {
    background-color: var(--block-content-bg-color-three);
  }
  &--four {
    background-color: var(--block-content-bg-color-four);
  }
  &--five {
    background-color: var(--block-content-bg-color-five);
  }
}
