@import "../../assets/sass/mixins.scss";
.base-currency {
  &__control {
    padding: var(--base-currency-control-pd);
    padding-right: 0;
    box-shadow: none !important;
    flex-wrap: nowrap !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;

    @include screen(md) {
      padding: var(--base-currency-control-pd-md);
    }
  }
  &__input-container {
    display: none !important;
  }
  &__value-container {
    padding: 0 !important;
  }
  &__single-value {
    display: flex;
    margin: 0 !important;
  }
  &__indicator {
    padding: var(--base-currency-indicator-pd) !important;

    cursor: pointer !important;

    .icon {
      fill: var(--base-currency-indicator-icon-fill);
      transition: all var(--transition) ease 0s;

      &:hover {
        fill: var(--base-currency-indicator-icon-fill-hover);
      }
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    margin-top: var(--base-currency-menu-mt) !important;

    filter: var(--fillter-one);
    box-shadow: none !important;
  }
  &__menu-list {
    padding: 0 !important;
  }
  &__option {
    position: relative;

    display: flex !important;
    padding: var(--base-currency-option-pd) !important;

    cursor: pointer !important;

    &:hover {
      background-color: var(--base-currency-option-bg-color-hover) !important;
    }
    &:first-child {
      border-radius: var(--base-currency-option-border-radius) var(--base-currency-option-border-radius) 0 0;
    }
    &:last-child {
      border-radius: 0 0 var(--base-currency-option-border-radius) var(--base-currency-option-border-radius);
    }
  }
  &__option--is-selected {
    background-color: transparent !important;

    &::before {
      position: absolute;
      top: 50%;
      right: 4px;

      display: flex;
      width: var(--base-currency-option-check-size);
      height: var(--base-currency-option-check-size);

      content: "";
      background-image: url(../../assets/icon/icon--check-grey.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform: translateY(-50%);

      @include screen(md) {
        right: 8px;
      }
    }
  }
  &__option--is-focused {
    background-color: transparent !important;
  }
}
