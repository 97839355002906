@import "../../../../assets/sass/mixins.scss";
.page-requisite-drop-down {
  display: flex;
  justify-content: center;
  margin-bottom: var(--space-2s);

  @include screen(lg) {
   justify-content: flex-end;
   margin-bottom: var(--space-3m);
  }
}