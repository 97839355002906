@import "../../assets/sass/mixins.scss";
.dropdown-actions {
  position: relative;

  &__body {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: var(--dropdown-actions-column-gap-mob);

    @include screen(lg) {
      column-gap: var(--dropdown-actions-column-gap-lg);
    }
  }
  &__dropdown {
    position: absolute;
    right: 0;
    z-index: 2;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--dropdown-actions-dropdown-row-gap);
    width: auto;
    padding: var(--dropdown-actions-dropdown-padding);
    
    background-color: var(--dropdown-actions-dropdown-bg-color);
    box-shadow: var(--shadow-one);
    border-radius: var(--dropdown-actions-dropdown-border-r);
    
    transition: all var(--transition) ease 0s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    
    &--open {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
    &--bottom{
      top: var(--dropdown-actions-dropdown-top);

    }
    &--top{
      bottom: var(--dropdown-actions-dropdown-top);

    }
  }
}
