.input-calendar{
    position: relative;

    &__show{
        position: absolute;
        top: calc(10px + var(--input-label-height) + var(--input-input-height));
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
    }
    .react-calendar__navigation{
        
        background-color: var(--background-secondary);
    }
}