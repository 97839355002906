@import "../../../../assets/sass/mixins.scss";
.chat-document {
  display: flex;
  align-items: center;
  column-gap: var(--space-2xs);
  padding-top: var(--chat-document-pd);

  .icon-text {
    flex: 1 1 auto;

    .text {
      display: inline-block;
      max-width: 136px;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include screen(esm) {
        max-width: 190px;
      }
      @include screen(sm) {
        max-width: 340px;
      }
      @include screen(md) {
        max-width: 300px;
      }
      @include screen(lg) {
        max-width: 450px;
      }
    }
  }
  &:not(:last-child) {
    border-bottom: var(--chat-border-width) solid var(--chat-border-color);
    padding: var(--chat-document-pd) 0;
  }
}
