.input {
  position: relative;

  width: 100%;

  &__label {
    display: flex;
    min-height: var(--input-label-height);
    box-sizing: border-box;
    padding-bottom: 4px;
  }
  &__body {
    position: relative;

    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: var(--input-input-padding);
    height: var(--input-input-height);

    color: var(--input-font-color);

    border: var(--input-border-width) solid var(--input-border-color);
    border-radius: var(--input-border-radius);
    background-color: var(--input-bg-color);
    cursor: pointer;
    outline: none;

    transition: all 0.4s;

    &:hover {
      border-color: var(--input-border-color-hover);
    }
    &:focus-within {
      border-color: var(--input-border-color-hover);
    }
    &:active {
      border-color: var(--input-border-color-active);
    }
  }
  &__body--disabled {
    color: var(--input-disabled-color);

    cursor: not-allowed;
    background-color: var(--input-disabled-bg-color);
    border-color: var(--input-disabled-border-color);

    & + .input__icon--check {
      background-color: var(--input-disabled-icon-check);
    }

    &:hover {
      border-color: var(--input-disabled-border-color);
    }
    .input__input {
      cursor: not-allowed;

      color: var(--input-disabled-color);

      user-select: none;
      background-color: var(--input-disabled-bg-color);
    }
    .input__icon--value {
      background-color: var(--input-disabled-icon-check);
    }
  }
  &__input {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    font-family: var(--input-font-family);
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    color: currentColor;

    outline: none;

    &::-webkit-input-placeholder {
      color: var(--input-placeholder-color);
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &::-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-moz-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;

      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: var(--input-placeholder-color);
      text-overflow: ellipsis;
    }
    &[placeholder] {
      text-overflow: ellipsis;
    }
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type="number"],
    &[type="number"]:hover,
    &[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
    }
  }
  &__notification {
    display: flex;
    padding-top: 5px;
    box-sizing: border-box;
    min-height: var(--input-notification-height);
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--input-input-height);
    width: var(--input-input-height);
    flex: 0 0 var(--input-input-height);

    border-radius: 0 var(--input-border-radius) var(--input-border-radius) 0;

    .icon {
      fill: var(--input-icon-fill);
    }
  }
  &__icon--check {
    transition: all 0.4s;

    .icon {
      opacity: 0;
      visibility: hidden;

      transition: all 0.4s;
    }
  }
  &__icon--value {
    background-color: var(--input-icon-check-bg-color);

    .icon {
      opacity: 1;
      visibility: visible;
      fill: var(--color-white);
    }
  }
}
.input--error {
  .input__notification {
    color: var(--input-error-font-color);
  }
}
// .input input:disabled {
// 	/* 3 Typography */
// 	color: gray;

// 	/* 4 Visual */
// 	cursor: not-allowed;
// 	background-color: #ffffff;
// 	border: 3px solid pink;
// }
