/* Color */
.text--color-primary {
  color: var(--color-text);
}
.text--color-secondary {
  color: var(--color-text-secondary);
}
.text--color-white {
  color: var(--color-white);
}
.text--color-red {
  color: var(--color-red-mat);
}
