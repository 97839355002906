@import "../../assets/sass/mixins.scss";

.update-global-commission {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-m);
  column-gap: var(--space-3s);

  @include screen(lg) {
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 0;
  }

  &__col {
    width: 100%;
    flex: 1 1 100%;

    @include screen(lg) {
      width: calc(50% - var(--space-3s) / 2);
      flex: 0 1 calc(50% - var(--space-3s) / 2);
    }
  }

  &__group-item {
    display: flex;
    flex-direction: column;
    column-gap: var(--space-3s);

    @include screen(lg) {
      flex-direction: row;
      align-items: flex-end;
    }

    .btn {
      @include screen(lg) {
        margin-bottom: var(--input-notification-height);
      }
    }
  }

  &__col--full {
    width: 100%;
    flex: 1 1 100%;

    .update-global-commission__group-item {
      row-gap: var(--space-m);
      .btn {
        margin-bottom: 0;
      }
      @include screen(lg) {
        justify-content: center;
      }
    }
  }
}

// @include screen(md) {
// }
// --space-xs: 4px;
// --space-2xs: 8px;
// --space-s: 10px;
// --space-2s: 12px;
// --space-3s: 16px;
// --space-4s: 18px;
// --space-m: 20px;
// --space-2m: 22px;
// --space-3m: 24px;
// --space-4m: 28px;
// --space-l: 30px;
// --space-2l: 32px;
// --space-xl: 40px;
// --space-2xl: 52px;