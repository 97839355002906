@import "../../../assets/sass/mixins.scss";
.tooltips {
  position: relative;
  display: inline-flex;

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    cursor: pointer;
    outline: none;

    background-color: transparent;
    border: none;

    .icon {
      fill: var(--tooltips-icon-fill);

      transition: all var(--transition) ease 0s;
    }

    &:hover {
      .icon {
        fill: var(--tooltips-icon-fill-hover);
      }
    }
  }
  &__content {
    position: absolute;
    z-index: var(--tooltips-content-z-index);

    display: flex;
    width: var(--tooltips-content-width);
    padding: var(--tooltips-content-padding);
    box-sizing: border-box;

    visibility: hidden;
    color: var(--tooltips-content-color);
    background-color: var(--tooltips-content-bg-color);
    border-radius: var(--tooltips-content-border-radius);

    @include screen(esm) {
      width: var(--tooltips-content-width-esm);
    }

    &::before {
      position: absolute;
      top: 0;
      left: 50%;

      display: block;
      width: var(--tooltips-content-before-size);
      height: var(--tooltips-content-before-size);

      content: "";
      background-color: var(--tooltips-content-bg-color);
    }

    &--top {
      bottom: var(--tooltips-content-position-y);
      left: 50%;

      transform: translateX(-50%);

      &::before {
        top: auto;
        bottom: calc(-1 * var(--tooltips-content-before-position));
        left: 50%;

        transform: translateX(-50%) rotate(45deg);
      }
    }

    &--bottom {
      top: var(--tooltips-content-position-y);
      left: 50%;

      transform: translateX(-50%);

      &::before {
        top: calc(-1 * var(--tooltips-content-before-position));
        left: 50%;

        transform: translateX(-50%) rotate(45deg);
      }
    }

    &--right {
      right: calc(-1 * (var(--tooltips-content-position-x) + var(--tooltips-content-width)));
      top: 50%;

      transform: translateY(-50%);

      &::before {
        left: calc(-1 * var(--tooltips-content-before-position));
        top: 50%;

        transform: translateY(-50%) rotate(45deg);
      }
      @include screen(esm) {
        right: calc(-1 * (var(--tooltips-content-position-x) + var(--tooltips-content-width-esm)));
      }
    }
    &--left {
      left: calc(-1 * (var(--tooltips-content-position-x) + var(--tooltips-content-width)));
      top: 50%;

      transform: translateY(-50%);

      @include screen(esm) {
        left: calc(-1 * (var(--tooltips-content-position-x) + var(--tooltips-content-width-esm)));
      }

      &::before {
        right: calc(-1 * var(--tooltips-content-before-position));
        top: 50%;
        left: auto;

        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &__content--open {
    visibility: visible;
  }
  &__stories {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    box-sizing: border-box;
    min-height: 100vh;
  }
}
