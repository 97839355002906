.icon-img {
  display: inline-flex;

  &--size-base {
    width: var(--icon-img-size-base);
    height: var(--icon-img-size-base);
  }
  &--size-big {
    width: var(--icon-img-size-big);
    height: var(--icon-img-size-big);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-3s);
    padding: var(--space-m);
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--space-s);

    font-family: var(--font-main);
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-16);
    color: var(--color-blue);
  }
}
