:root {
  /* Base */

  /* Base Color */
  --color-black: #000000;
  --color-white: #ffffff;

  --color-blue: #2d58b6;
  --color-blue-hover: #3163f0;
  --color-blue-active: #244ab7;

  --color-disable: #d0d1d8;
  --color-disable-background: #f3f3f5;

  --color-button-border-default: #d9d9d9;
  --color-button-text-secondary: #9296a5;
  --color-button-secondary-active: #fafafb;
  --color-disable-border: #d0d1d8;

  --color-green-mat: #33b375;
  --color-green-hover: #239a60;
  --color-green-active: #1c8250;
  --color-red-mat: #f84545;
  --color-orange-mat: #ff8202;
  --color-blue-mat: #3d68ff;

  --color-green-bg-mat: #f3faf4;
  --color-orange-bg-mat: #fff4e6;
  --color-red-bg-mat: #feeeee;
  --color-blue-bg-mat: #eff2fb;

  --color-green-bg-light: #f2fdf8;
  --color-red-bg-light: #fff5f5;

  --color-text: #202d52;
  --color-text-secondary: var(--color-button-text-secondary);
  --color-red-disable: #f39a9a;
  --color-blue-disable: #a7beff;

  /* Base Gradient */
  --gradient-white-: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --gradient-blue: linear-gradient(180deg, rgba(52, 103, 254, 0.4) 0%, rgba(52, 103, 254, 0) 100%);
  --gradient-background-app: linear-gradient(0deg, #202d52 0%, #4076ec 100%);
  --gradient-illustration: linear-gradient(90deg, #4cd9ed -0.45%, #47a6ec 53.57%, #437fec 99.59%);
  --gradient-illustration-2: linear-gradient(90deg, #55efbd -0.32%, #5ae6c1 12.6%, #38c9dd 99.07%);
  --gradient-3322: linear-gradient(177.89deg, #f53167 1.78%, #fa726c 102.08%);

  /* Base Shadow */
  --shadow-one: 0px 4px 24px rgba(3, 15, 61, 0.2);
  --shadow-two: 0px 4px 24px rgba(3, 15, 61, 0.2);
  --fillter-one: drop-shadow(0px 4px 24px rgba(3, 15, 61, 0.2));

  /* Base Font */
  --font-main: "Proxima", "Sans Serif";
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-28: 28px;
  --font-size-32: 32px;

  --font-line-height-16: 16px;
  --font-line-height-18: 18px;
  --font-line-height-20: 20px;
  --font-line-height-24: 24px;
  --font-line-height-26: 26px;
  --font-line-height-32: 32px;
  --font-line-height-34: 34px;
  --font-line-height-36: 36px;

  /* Base Body */
  --body-font-size: var(--font-size-14);
  --body-line-height: var(--font-line-height-24);
  --body-color: var(--color-text);

  /* Base Grid */
  --container-width-media-sm: 540px;
  --container-width-media-md: 720px;
  --container-width-media-lg: 960px;
  --container-width-media-xl: 1170px;
  --container-width: 1170px;

  --bases-gutter-mob-x: 20px;
  --bases-gutter-x: 16px;

  --grid-breakpoints-xs: 0;
  --grid-breakpoints-sm: 576px;
  --grid-breakpoints-md: 768px;
  --grid-breakpoints-lg: 992px;
  --grid-breakpoints-xl: 1200px;
  --grid-breakpoints-xxl: 1400px;

  --space-xs: 4px;
  --space-2xs: 8px;
  --space-s: 10px;
  --space-2s: 12px;
  --space-3s: 16px;
  --space-4s: 18px;
  --space-m: 20px;
  --space-2m: 22px;
  --space-3m: 24px;
  --space-4m: 28px;
  --space-l: 30px;
  --space-2l: 32px;
  --space-xl: 40px;
  --space-2xl: 52px;

  /* Base Icon */
  --icon-size-base: 24px;
  --icon-size-sm: 12px;
  --icon-size-md: 20px;
  --icon-fill-base: var(--color-blue);
  --icon-fill-bg-icon: var(--color-bg-icon);
  --icon-stroke-base: var(--color-blue);

  /* Base Icon-Img */
  --icon-img-size-base: 24px;
  --icon-img-size-big: 87px;

  /* Base Link */
  --link-color: var(--color-primary);

  /* Border */
  --border-radius-base: 8px;
  --border-radius-md: 12px;
  --border-radius-lg: 16px;
  --border-radius-xl: 26px;
  --border-width-base: 1px;
  --border-width-m: 2px;
  --border-color-base: var(--color-button-border-default);
  --border-color-secondary: var(--color-blue);

  /* Background */
  --background-base: var(--color-blue);
  --background-secondary: var(--color-white);
  --background-three: var(--color-disable-background);
  --background-four: #e7ebf6;
  --background-five: #e5ecff;

  /* Transition */
  --transition: 0.4s;

  /* Components */

  /* Base Currency */
  --base-currency-control-pd: 0 var(--space-2xs);
  --base-currency-control-pd-md: 0 var(--space-2xs) 0 var(--space-m);
  --base-currency-indicator-pd: var(--space-xs);
  --base-currency-indicator-icon-fill: var(--color-button-text-secondary);
  --base-currency-indicator-icon-fill-hover: var(--color-blue);
  --base-currency-menu-mt: 6px;
  --base-currency-option-pd: var(--space-2s) var(--space-2xs);
  --base-currency-option-bg-color-hover: var(--color-disable-background);
  --base-currency-option-check-size: 24px;
  --base-currency-option-border-radius: 4px;

  /* Bids */
  --bids-padding: var(--space-3s);
  --bids-padding-md: var(--space-3m) var(--space-4m);
  --bids-border-radius: var(--border-radius-base);
  --bids-bottom-border-width: var(--border-width-base);
  --bids-bottom-border-color: var(--border-color-base);
  --bids-actions-padding: var(--space-2s) var(--space-3s);
  --bids-actions-padding-md: var(--space-3s) var(--space-4m);
  --bids-info-gap: var(--space-2xs);
  --bids-chat-bg-color: var(--background-secondary);

  /* Block Content */
  --block-content-pd-mob: var(--space-3s);
  --block-content-pd-lg: var(--space-4m);
  --block-content-border-r: var(--border-radius-base);
  --block-content-bg-color-base: var(--background-secondary);
  --block-content-bg-color-secondary: var(--background-secondary);
  --block-content-bg-color-three: var(--background-three);
  --block-content-bg-color-four: var(--background-four);
  --block-content-bg-color-five: var(--background-five);

  /* Body */
  --body-background-color: var(--background-three);

  /* Button */
  --button-height: 52px;
  --button-padding: 10px 24px;
  --button-column-gap: 8px;

  --button-font-family: var(--font-main);
  --button-font-size: var(--font-size-16);
  --button-line-height: var(--font-line-height-20);
  --button-color: var(--color-white);
  --button-background: var(--color-blue);
  --button-border-width: var(--border-width-base);
  --button-border-color: var(--border-color-secondary);
  --button-border-radius: var(--border-radius-base);
  --button-icon-fill: var(--color-white);

  --button-border-color-hover: var(--color-blue-hover);
  --button-background-color-hover: var(--color-blue-hover);

  --button-border-color-active: var(--color-blue-active);
  --button-background-color-active: var(--color-blue-active);

  --button-border-color-disabled: var(--color-disable-background);
  --button-background-color-disabled: var(--color-disable-background);
  --button-color-disabled: var(--color-disable);
  --button-icon-fill-disabled: var(--color-disable);

  --button-size-sm: 40px;
  --button-padding-sm: 6px 20px;

  --button-size-md: 38px;
  --button-padding-md: 6px 18px;
  --button-size-md-md: 44px;
  --button-padding-md-md: 6px 24px;

  --btn-flat-color: var(--color-button);
  --btn-flat-color-hover: var(--color-button-hover);
  --btn-flat-color-active: var(--color-button-active);
  --btn-flat-color-disable: var(--color-button-disable);

  --button-outline-border-color: var(--color-button-border-default);
  --button-outline-color: var(--color-button-text-secondary);
  --button-outline-icon-fill: var(--color-button-text-secondary);

  --button-outline-border-color-hover: var(--color-blue-hover);
  --button-outline-color-hover: var(--color-blue-hover);
  --button-outline-icon-fill-hover: var(--color-blue-hover);

  --button-outline-border-color-active: var(--color-blue-active);
  --button-outline-color-active: var(--color-blue-active);
  --button-outline-icon-fill-active: var(--color-blue-active);

  --button-outline-border-color-disable: var(--color-disable);
  --button-outline-color-disable: var(--color-disable);
  --button-outline-icon-fill-disable: var(--color-disable);
  --button-outline-background-disabled: var(--color-disable-background);

  --button-icon-padding: 14px;

  --button-flat-color: var(--color-blue);
  --button-flat-icon-fill: var(--color-blue);
  --button-flat-color-hover: var(--color-blue-hover);
  --button-flat-icon-fill-hover: var(--color-blue-hover);
  --button-flat-color-active: var(--color-blue-active);
  --button-flat-icon-fill-active: var(--color-blue-active);
  --button-flat-color-disabled: var(--color-disable);
  --button-flat-icon-fill-disabled: var(--color-disable);

  --button-flat-secondary-color: var(--color-button-text-secondary);
  --button-flat-secondary-icon-fill: var(--color-button-text-secondary);
  --button-flat-secondary-color-hover: var(--color-blue-hover);
  --button-flat-secondary-icon-fill-hover: var(--color-blue-hover);
  --button-flat-secondary-color-active: var(--color-blue-active);
  --button-flat-secondary-icon-fill-active: var(--color-blue-active);
  --button-flat-secondary-color-disabled: var(--color-disable);
  --button-flat-secondary-icon-fill-disabled: var(--color-disable);

  --button-secondary-color: var(--color-white);
  --button-secondary-bg: var(--color-green-mat);
  --button-secondary-border-color: var(--color-green-mat);

  --button-secondary-bg-hover: var(--color-green-hover);
  --button-secondary-border-color-hover: var(--color-green-hover);

  --button-secondary-bg-active: var(--color-green-active);
  --button-secondary-border-color-active: var(--color-green-active);

  --button-danger-color: var(--color-white);
  --button-danger-bg: var(--color-red-mat);
  --button-danger-border-color: var(--color-red-mat);

  --button-danger-bg-hover: var(--color-red-mat);
  --button-danger-border-color-hover: var(--color-red-mat);
  --button-danger-opacity-hover: 0.7;

  --button-danger-bg-active: var(--color-red-disable);
  --button-danger-border-color-active: var(--color-red-disable);

  --button-danger-secondary-color: var(--color-red-mat);
  --button-danger-secondary-bg-color: var(--color-red-bg-light);
  --button-danger-secondary-border-color: var(--color-red-mat);
  --button-danger-secondary-icon-fill: var(--color-red-mat);

  --button-danger-secondary-hover-color: var(--color-white);
  --button-danger-secondary-hover-bg-color: var(--color-red-mat);
  --button-danger-secondary-hover-border-color: var(--color-red-mat);
  --button-danger-secondary-hover-icon-fill: var(--color-white);

  --button-danger-secondary-active-color: var(--color-white);
  --button-danger-secondary-active-bg-color: var(--color-red-mat);
  --button-danger-secondary-active-border-color: var(--color-red-mat);
  --button-danger-secondary-active-icon-fill: var(--color-white);
  --button-danger-secondary-active-opacity: 0.7;

  --button-danger-secondary-disabled-color: var(--color-disable);
  --button-danger-secondary-disabled-icon-fill: var(--color-disable);
  --button-danger-secondary-disabled-bg-color: var(--button-background-color-disabled);
  --button-danger-secondary-disabled-border-color: var(--button-border-color-disabled);

  /* Chat */
  --chat-bg-color: var(--background-secondary);
  --chat-border-radius: var(--border-radius-base);
  --chat-border-color: var(--border-color-base);
  --chat-border-width: var(--border-width-base);

  --chat-left-width: 260px;
  --chat-body-body-wrapper-height: 227px;
  --chat-body-body-wrapper-height-md: 667px;
  --chat-document-pd: 14px;

  --chat-list-bg-color: var(--color-disable-background);
  --chat-list-border-radius: var(--border-radius-lg);
  --chat-text-area-pd: 14px 38px 14px 16px;

  --chat-text-area-height: 52px;
  --chat-text-area-pd: 14px 38px 14px 16px;
  --chat-text-area-border-radius: var(--border-radius-md);

  --chat-list-bids-height: 356px;
  --chat-list-bids-height-md: 628px;
  --chat-list-bids-icon-size: 52px;
  --chat-list-bids-pd: 14px;
  --chat-list-bids-item-number-postition-top: -5px;
  --chat-list-bids-item-number-postition-right: -5px;
  --chat-count-bids-item-position-right: -10px;
  --chat-count-bids-item-position-top: -10px;

  --chat-short-body-wrapper-height: 414px;
  --chat-short-body-wrapper-height-md: 377px;

  /* Checkbox */
  --checkbox-size: 24px;
  --checkbox-color: var(--color-text);
  --checkbox-border-width: var(--border-width-base);
  --checkbox-border-color: var(--border-color-base);
  --checkbox-border-radius: 3px;
  --checkbox-width-before: 15px;
  --checkbox-height-before: 11px;

  --checkbox-border-color-hover: var(--color-blue-hover);
  --checkbox-background-color-checked: var(--background-base);
  --checkbox-border-color-checked: var(--color-blue);

  --checkbox-background-color-checked-hover: var(--color-blue-hover);
  --checkbox-border-color-checked-hover: var(--color-blue-hover);

  --checkbox-background-color-checked-disabled: var(--color-disable);
  --checkbox-background-color-disabled: var(--color-disable-background);
  --checkbox-border-color-checked-disabled: var(--color-disable);

  /* Chips */
  --chips-padding: 3px 16px;
  --chips-padding-lg: 6px 20px;
  --chips-color: var(--color-button-text-secondary);
  --chips-border-width: var(--border-width-base);
  --chips-border-color: var(--border-color-base);
  --chips-border-radius: var(--border-radius-xl);

  --chips-color-hover: var(--color-blue-hover);
  --chips-border-color-hover: var(--color-blue-hover);

  --chips-color-active: var(--color-blue-active);
  --chips-border-color-active: var(--color-blue-active);
  --chips-bg-active: var(--color-blue-bg-mat);

  --chips-color-disable: var(--color-disable);
  --chips-border-color-disable: var(--border-color-base);
  --chips-bg-disable: var(--color-disable-background);

  /* Calendar Custom */
  --calendar-custom-label-height: 24px;
  --calendar-custom-day-height: 32px;
  --calendar-custom-font-size: 12px;
  --calendar-custom-line-height: 16px;
  --calendar-custom-calendar-width: 290px;

  --calendar-custom-navigation-height: 44px;
  --calendar-custom-navigation-pb: 10px;
  --calendar-custom-navigation-arrow-hover: var(--color-blue-bg-mat);
  --calendar-custom-navigation-arrow-icon-fill-hover: var(--color-blue);
  --calendar-custom-navigation-arrow-icon-fill: var(--color-button-text-secondary);
  --calendar-custom-navigation-border-width: var(--border-width-base);
  --calendar-custom-navigation-border-color: var(--border-color-base);
  --calendar-custom-navigation-border-radius: var(--border-radius-base);

  --calendar-custom-view-container-bg-color: var(--background-secondary);

  --calendar-custom-month-view-weekdays-weekday: var(--color-text-secondary);
  --calendar-custom-month-view-days-day-weekend: #eb5757;
  --calendar-custom-month-view-days-day-neighboringMonth: var(--color-disable);
  --calendar-custom-month-view-days-day-neighboringMonth-disable: var(--color-red-disable);

  --calendar-custom-tile-active-color: var(--color-text);
  --calendar-custom-tile-bg-color: var(--background-base);
  --calendar-custom-tile-color: var(--color-white);
  --calendar-custom-tile-border-radius: 4px;

  --calendar-custom-century-view-decades-decad-height: 56px;

  --calendar-custom-decade-century-year-height: 32px;
  --calendar-custom-decade-year-view-color: var(--color-text);
  --calendar-custom-decade-year-view-color-hover: var(--color-blue-hover);

  /* Counter Round */
  --counter-round-size: 24px;
  --counter-round-font-family: var(--font-main);
  --counter-round-font-size: 11px;
  --counter-round-line-height: 15px;
  --counter-round-font-weight: 700;
  --counter-round-color: var(--color-white);
  --counter-round-bg-color: var(--color-blue-mat);

  /* Dropdown Actions */
  --dropdown-actions-column-gap-mob: var(--space-2s);
  --dropdown-actions-column-gap-lg: var(--space-4s);

  --dropdown-actions-dropdown-top: 35px;
  --dropdown-actions-dropdown-row-gap: var(--space-2s);
  --dropdown-actions-dropdown-padding: var(--space-3s);
  --dropdown-actions-dropdown-bg-color: var(--background-secondary);
  --dropdown-actions-dropdown-border-r: var(--border-radius-base);

  /* Dropdown Quantity */
  --dropdown-quantity-column-gap: var(--space-3s);
  --dropdown-quantity-text-color: var(--color-text);
  --dropdown-quantity-font-size: var(--font-size-16);
  --dropdown-quantity-line-height: var(--font-line-height-24);

  --dropdown-quantity-border-radius: var(--border-radius-base);
  --dropdown-quantity-border-color: var(--border-color-base);
  --dropdown-quantity-border-width: var(--border-width-base);
  --dropdown-quantity-bg-color: var(--background-secondary);
  --dropdown-quantity-border-color-active: var(--border-color-secondary);

  --dropdown-quantity-control-pd: var(--space-2s) var(--space-2s) var(--space-2s) var(--space-3s);
  --dropdown-quantity-control-height: 50px;
  --dropdown-quantity-control-min-width: 88px;

  --dropdown-quantity-indicator-pd: 0 0 0 var(--space-xs);
  --dropdown-quantity-indicator-icon-fill: var(--color-text-secondary);
  --dropdown-quantity-indicator-icon-fill-hover: var(--color-blue);

  --dropdown-quantity-menu-list-min-height: 50px;
  --dropdown-quantity-menu-list-max-height: 450px;
  --dropdown-quantity-option-pd: 14px 16px;

  /* Hamburger */
  --hamburger-width: 24px;
  --hamburger-height: 24px;
  --hamburger-body-width: 18px;
  --hamburger-body-height: 12px;
  --hamburger-band-height: 13%;
  --hamburger-background-color: var(--color-text);
  --hamburger-background-color-hover: var(--color-blue-hover);

  /* Header*/
  --header-height: 76px;
  --header-border-width: var(--border-width-base);
  --header-border-color: var(--border-color-base);
  --header-background-color: var(--color-white);

  /* History Table */
  --history-table-bg-color: var(--background-secondary);
  --history-table-border-radius: var(--border-radius-base);
  --history-table-border-width: var(--border-width-base);
  --history-table-border-color: var(--border-color-base);
  --history-table-header-height: 57px;

  /* History Table Operations */
  --history-table-operations-bg-color: var(--background-secondary);
  --history-table-operations-border-radius: var(--border-radius-base);
  --history-table-operations-border-width: var(--border-width-base);
  --history-table-operations-border-color: var(--border-color-base);
  --history-table-operations-pd: var(--space-2s) var(--space-3s);
  --history-table-operations-pd-lg: var(--space-m) var(--space-xl) var(--space-m) var(--space-l);

  /* Icon-Text */
  --icon-text-gap-esm: 2px;
  --icon-text-gap-sm: 4px;
  --icon-text-gap-m: 6px;
  --icon-text-gap-lg: 8px;
  --icon-text-gap-xl: 10px;
  --icon-text-gap-2xl: 12px;

  /* Input */
  --input-label-height: 24px;
  --input-input-height: 52px;
  --input-input-padding: 16px 0 16px 16px;
  --input-notification-height: 24px;
  --input-border-width: var(--border-width-base);
  --input-border-color: var(--border-color-base);
  --input-border-radius: var(--border-radius-md);
  --input-bg-color: var(--color-white);
  --input-font-family: var(--font-main);
  --input-font-size: var(--font-size-16);
  --input-line-height: var(--font-line-height-24);
  --input-font-color: var(--color-text);
  --input-placeholder-color: var(--color-text-secondary);
  --input-icon-fill: var(--color-button-text-secondary);
  --input-icon-check-bg-color: var(--background-base);

  --input-error-font-color: var(--color-red-mat);

  --input-border-color-hover: var(--color-button-text-secondary);
  --input-border-color-active: var(--color-blue);
  --input-disabled-color: var(--color-button-text-secondary);
  --input-disabled-bg-color: var(--color-disable-background);
  --input-disabled-border-color: var(--color-disable-border);
  --input-disabled-icon-check: var(--color-disable);

  /* Langs */
  --langs-control-pd: 0 var(--space-2xs);
  --langs-control-pd-md: 0 var(--space-2xs) 0 var(--space-m);
  --langs-indicator-pd: var(--space-xs);
  --langs-indicator-icon-fill: var(--color-button-text-secondary);
  --langs-indicator-icon-fill-hover: var(--color-blue);
  --langs-menu-mt: 6px;
  --langs-option-pd: var(--space-2s) var(--space-2xs);
  --langs-option-bg-color-hover: var(--color-disable-background);
  --langs-option-check-size: 24px;
  --langs-option-border-radius: 4px;

  /* List Chips */
  --list-chips-mb: var(--space-2s);
  --list-chips-body-gap: var(--space-2xs);

  /* List Info */
  --list-info-item-border: var(--border-width-base) solid var(--border-color-base);

  /* Link */
  --link-color: var(--color-button-text-secondary);
  --link-hover-color: var(--color-blue-hover);

  /* Logo */
  --logo-width: 173px;
  --logo-width-mob: 121px;

  /* Mark */
  --mark-color-primary: var(--color-blue);

  /* Menu */
  --menu-list-row-gap: var(--space-m);
  --menu-list-row-gap-xl: var(--space-3m);
  --menu-icon-fill: var(--color-button-text-secondary);
  --menu-text-color: var(--color-text-secondary);

  --menu-icon-fill-active: var(--icon-fill-base);
  --menu-text-color-active: var(--color-text);

  /* Modal */
  --modal-wrapper-width-base: 804px;
  --modal-wrapper-width-sm: 600px;
  --modal-wrapper-width-md: 700px;
  --modal-content-bg-color-base: var(--background-secondary);
  --modal-content-padding-base: 22px;
  --modal-wrapper-padding-base: 0;
  --modal-pd-left: var(--space-4m);
  --modal-pd-right: var(--space-4m);
  --modal-pd-left-md: var(--space-xl);
  --modal-pd-right-md: var(--space-xl);

  /* News */
  --news-item-pd: 16px 40px;
  --news-item-pd-md: 15px 48px;
  --news-item-bg-color: var(--background-four);
  --news-item-border-color: var(--color-button-border-default);
  --news-item-border-width: 1px;
  --news-item-border-r: var(--border-radius-base);

  --news-body-height: 45px;

  /* Operation Number State */
  --operation-number-state-color-down: var(--color-green-mat);
  --operation-number-state-color-up: var(--color-red-mat);
  --operation-number-state-color-all-icon: #0f3042;

  /* Operation State */
  --operation-state-bg-color-sale: #ffeded;
  --operation-state-bg-color-buy: #e8fff5;
  --operation-state-bg-color-empty: var(--color-disable-background);
  --operation-state-text-color-empty: var(--color-button-text-secondary);
  --operation-state-border-color-sale: #e87d7d;
  --operation-state-border-color-buy: #66dea4;

  /* Pagination */
  --pagination-column-gap: var(--space-s);
  --pagination-column-gap-md: var(--space-m);
  --pagination-color-hover: var(--color-blue-hover);
  --pagination-current-color: var(--color-blue-active);

  /* Radio */
  --radio-size: 24px;
  --radio-border-width: var(--border-width-base);
  --radio-border-color: var(--border-color-base);
  --radio-border-radius: 50%;
  --radio-width-before: 12px;
  --radio-height-before: 12px;
  --radio-border-radius-before: 50%;

  --radio-border-color-checked: var(--color-blue);
  --radio-background-color-before-checked: var(--color-blue);

  --radio-border-color-checked-hover: var(--color-blue-hover);
  --radio-background-color-before-checked-hover: var(--color-blue-hover);

  --radio-border-color-hover: var(--color-blue-hover);
  --radio-border-color-disabled: var(--color-disable-border);
  --radio-background-color-disabled: var(--color-disable-background);
  --radio-background-color-checked-disabled: var(--color-disable);
  --radio-color-disabled: var(--color-text-secondary);

  /* Requisite */
  --requisite-border-radius: var(--border-radius-base);
  --requisite-bottom-padding: var(--space-2s) var(--space-3s);
  --requisite-bottom-padding-lg: var(--space-3s) var(--space-4m);
  --requisite-bottom-border-width: var(--border-width-base);
  --requisite-bottom-border-color: var(--border-color-base);
  --requisite-desc-padding: var(--space-2s) var(--space-3s);
  --requisite-desc-padding-md: var(--space-3s) var(--space-4m);
  --requisite-desc-background-color: var(--background-four);
  --requisite-border-radius: var(--border-radius-base);
  --requisite-desc-options-user-info-card-column-gap: var(--space-3s);
  --requisite-desc-options-user-info-card-row-gap: var(--space-3s);
  --requisite-desc-options-user-info-card-item-width: 180px;

  --requisite-name-width-name: 130px;
  --requisite-name-width-direction: 108px;
  --requisite-name-width-currency: 74px;
  --requisite-name-width-payment-system: 172px;
  --requisite-name-width-payment-system-xl: 162px;
  --requisite-name-width-applications-month: 150px;
  --requisite-name-width-applications-month-xl: 120px;
  --requisite-name-width-turnover-month: 160px;
  --requisite-name-width-turnover-month-xl: 140px;
  --requisite-name-width-actions: auto;

  /* Report Table */
  --report-table-row-gap: var(--space-2s);
  --report-table-padding: var(--space-m) var(--space-3s);
  --report-table-padding-md: 0 var(--space-4m);
  --report-table-border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
  --report-table-border-radius-all: var(--border-radius-base);
  --report-table-border-radius-bottom: 0 0 var(--border-radius-base) var(--border-radius-base);
  --report-table-background-color: var(--background-secondary);
  --report-table-border-width: var(--border-width-base);
  --report-table-border-color: var(--border-color-base);
  --report-table-head-height: 57px;
  --report-table-body-row-gap: var(--space-2s);
  --report-table-body-item-height: 64px;
  --report-table-bottom-height: 64px;
  --report-table-bottom-background-color: var(--background-base);
  --report-table-bottom-total-border-color: var(--color-white);
  --report-table-item-width-1: 19.264%;
  --report-table-item-width-2: 18.5%;
  --report-table-item-width-3: 21.93%;
  --report-table-item-width-4: 20.79%;
  --report-table-item-width-last: 17%;

  /* Select Custom */
  --select-custom-column-gap: var(--space-3s);
  --select-custom-text-color: var(--color-text);
  --select-custom-font-size: var(--font-size-16);
  --select-custom-line-height: var(--font-line-height-24);

  --select-custom-border-radius: var(--border-radius-base);
  --select-custom-border-color: var(--border-color-base);
  --select-custom-border-width: var(--border-width-base);
  --select-custom-bg-color: var(--background-secondary);
  --select-custom-border-color-active: var(--border-color-secondary);

  --select-custom-control-pd: var(--space-2s) var(--space-2s) var(--space-2s) var(--space-3s);
  --select-custom-control-height: 50px;
  --select-custom-control-min-width: 88px;

  --select-custom-indicator-pd: 0 0 0 var(--space-xs);
  --select-custom-indicator-icon-fill: var(--color-text-secondary);
  --select-custom-indicator-icon-fill-hover: var(--color-blue);

  --select-custom-menu-list-min-height: 50px;
  --select-custom-menu-list-max-height: 450px;
  --select-custom-option-pd: 14px 16px;

  /* Sidebar */
  --sidebar-width: 264px;
  --sidebar-width-xl: 23.12%;
  --sidebar-body-padding: 24px 20px 20px 20px;
  --sidebar-body-padding-xl: 32px 30px 40px 0;
  --sidebar-border-width: var(--border-width-base);
  --sidebar-border-color: var(--border-color-base);
  --sidebar-background-color: var(--background-three);

  /* Status */
  --status-padding: 4px 16px;
  --status-border-radius: var(--border-radius-base);
  --status-color-done: var(--color-green-mat);
  --status-color-processing: var(--color-orange-mat);
  --status-color-cancel: var(--color-red-mat);
  --status-color-checked: var(--color-blue-mat);

  --status-background-color-done: #d6fbdb;
  --status-background-color-processing: var(--color-orange-bg-mat);
  --status-background-color-cancel: var(--color-red-bg-mat);
  --status-background-color-checked: var(--color-blue-bg-mat);

  /* Switcher */
  --switcher-height: 52px;
  --switcher-border-radius: var(--border-radius-base);
  --switcher-border-width: var(--border-width-base);
  --switcher-border-color: var(--color-button-border-default);
  --switcher-bg: var(--color-disable-background);
  --switcher-border-color-hover: var(--color-blue-hover);
  --switcher-bg-hover: var(--color-blue-bg-mat);

  --switcher-button-padding: 12px 20px;
  --switcher-button-color: var(--color-button-text-secondary);
  --switcher-icon-fill: var(--color-button-text-secondary);

  --switcher-button-color-hover: var(--color-blue-hover);
  --switcher-icon-fill-hover: var(--color-blue-hover);
  --switcher-hover-before-bg-color: var(--color-blue-hover);

  --switcher-button-color-active: var(--color-white);
  --switcher-bg-active: var(--color-blue);
  --switcher-button-border-color-active: var(--color-blue-hover);
  --switcher-button-active-color-hover: var(--color-white);
  --switcher-button-icon-size: var(--icon-size-base);
  --switcher-button-text-fz: var(--font-size-14);
  --switcher-button-text-lh: var(--font-line-height-20);

  /* Tabs */
  --tabs-list-column-gap: var(--space-4m);
  --tabs-list-column-gap-lg: var(--space-2l);

  --tab-pb: var(--space-s);
  --tab-color: var(--color-button-text-secondary);
  --tab-text-column-gap: var(--space-xs);
  --tab-before-height: 2px;
  --tab-before-background-color: #2d58b6;

  --tab-color-hover: var(--color-blue-hover);
  --tab-color-active: var(--color-blue);
  --tab-color-disable: var(--color-disable);

  --tabs-header-select-padding-lg: var(--space-m) var(--space-4m);
  --tabs-header-select-background-color: var(--background-secondary);
  --tabs-header-select-border-radius: var(--border-radius-base);

  /* Text Area */
  --text-area-height: 52px;
  --text-area-pd: 14px 16px;
  --text-area-border-radius: var(--border-radius-base);
  --text-area-border-width: var(--border-width-base);
  --text-area-border-color: var(--border-color-base);
  --text-area: var();
  --text-area: var();

  /* Toggle */
  --toggle-visual-width: 52px;
  --toggle-visual-height: 28px;

  --toggle-width-before: 14px;
  --toggle-height-before: 14px;
  --toggle-visual-before-position-x: 4px;

  --toggle-width-before: 20px;
  --toggle-height-before: 20px;
  --toggle-background-color-before: var(--color-white);
  --toggle-border-radius-before: 50%;

  --toggle-background-color: var(--color-disable);
  --toggle-border-radius: 30px;

  --toggle-background-color-hover: var(--color-button-text-secondary);
  --toggle-background-color-checked: var(--background-base);
  --toggle-background-color-checked-hover: var(--color-blue-hover);
  --toggle-background-color-disabled: var(--color-disable);

  /* Tooltips */
  --tooltips-icon-fill: var(--color-button-text-secondary);
  --tooltips-icon-fill-hover: var(--color-blue);
  --tooltips-content-z-index: 2;
  --tooltips-content-position-y: 36px;
  --tooltips-content-position-x: 12px;
  --tooltips-content-width: 220px;
  --tooltips-content-width-esm: 272px;
  --tooltips-content-padding: 16px;
  --tooltips-content-color: var(--color-white);
  --tooltips-content-bg-color: var(--color-blue);
  --tooltips-content-border-radius: var(--border-radius-base);
  --tooltips-content-before-size: 12px;
  --tooltips-content-before-position: 6px;

  /* User Info Card */
  --user-info-card-row-gap: var(--space-xs);
  --user-info-card-name-row-gap: var(--space-xs);
  --user-info-card-value-column-gap: var(--space-2xs);
  --user-info-card-icon-fill: var(--color-green-mat);
}
