@import "../../assets/sass/mixins.scss";
.user-info-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  row-gap: var(--user-info-card-row-gap);

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: var(--space-2xs);

    @include screen(lg) {
      justify-content: flex-start;
    }
  }
  &__name {
    display: inline-flex;
    align-items: center;
    column-gap: var(--user-info-card-name-row-gap);
  }
  &__value {
    display: inline-flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    column-gap: var(--user-info-card-value-column-gap);

    @include screen(lg) {
      justify-content: flex-start;
    }

    .text {
      text-align: right;

      @include screen(lg) {
        text-align: left;
      }
    }

    .icon {
      fill: var(--user-info-card-icon-fill);
    }
  }
}
