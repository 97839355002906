@import "../../../assets/sass/mixins.scss";
.modal-body {
  overflow: hidden;
}
.modal-overlay {
  position: fixed;
  z-index: 1050;

  background-color: rgba(0, 27, 102, 0.3);
  inset: 0px;
}
.modal-wrapper {
  position: absolute;
  top: 0;
  right: -1000px;
  bottom: 0;
  overflow-y: auto;

  width: 100%;
  max-width: var(--modal-wrapper-width-base);
  padding: var(--modal-wrapper-padding-base);

  background: none;
  border: none;
  outline: none;

  scrollbar-width: thin;
  scrollbar-color: var(--background-base) var(--background-three);

  &::-webkit-scrollbar {
    width: 3px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: var(--background-three);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--background-base);
    border: 1px solid var(--background-base);
  }

  transition: all var(--transition) ease 0s;

  &__content {
    position: relative;

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    background-color: var(--modal-content-bg-color-base);
  }
  &__header-close {
    position: absolute;
    top: 18px;
    right: 20px;
    display: flex;

    @include screen(lg) {
      top: 28px;
      right: 40px;
    }
  }
  &__header {
    position: relative;
    display: flex;
    padding-left: var(--modal-pd-left);
    padding-right: var(--modal-pd-right);
    padding-top: var(--space-4m);
    padding-bottom: var(--space-4m);

    @include screen(lg) {
      padding-left: var(--modal-pd-left-md);
      padding-right: var(--modal-pd-right-md);
      padding-top: var(--space-2xl);
      padding-bottom: var(--space-2xl);
    }
  }
  &__body {
    display: flex;
    align-self: normal;
    flex: 1 1 auto;

    & > * {
      width: 100%;
    }
  }
  & .btn--icon:hover .icon {
    fill: var(--button-icon-fill-hover-secondary);
  }
  &--center {
    .modal-wrapper__header {
      text-align: center;
      justify-content: center;
    }
    .modal-wrapper__body {
      text-align: center;
    }
  }
}
.modal-wrapper--size {
  &-sm {
    max-width: var(--modal-wrapper-width-sm);
  }
  &-md {
    max-width: var(--modal-wrapper-width-md);
  }
}
.modal-wrapper--no-close {
  .modal-wrapper__header {
    padding-right: 0;
  }
  .modal-wrapper__header-close {
    display: none;
  }
}
.ReactModal__Content--after-open {
  right: 0;
}
