.checkbox {
  display: inline-flex;
  column-gap: var(--space-2s);
  align-items: flex-start;

  color: var(--checkbox-color);

  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &__label {
    position: relative;

    display: flex;
    align-items: center;
    min-height: var(--checkbox-size);
    column-gap: var(--space-2s);

    color: var(--checkbox-color);

    cursor: pointer;
  }
  &__input[type="checkbox"] {
    position: absolute;

    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }
  &__input[type="checkbox"] + span {
    position: relative;

    box-sizing: border-box;
    display: inline-flex;
    width: var(--checkbox-size);
    height: var(--checkbox-size);

    background-color: transparent;
    border: var(--checkbox-border-width) solid var(--checkbox-border-color);
    border-radius: var(--checkbox-border-radius);

    transition: all var(--transition) ease 0s;
  }
  &__input[type="checkbox"]:checked + span {
    border: var(--checkbox-border-width) solid var(--checkbox-border-color-checked);
    background-color: var(--checkbox-background-color-checked);
  }
  &__input[type="checkbox"]:checked + span::before {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: var(--checkbox-width-before);
    height: var(--checkbox-height-before);

    content: "";
    background-image: url(../../../assets/icon/icon--checkbox-check.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transform: translate(-50%, -50%);
  }
  &__input[type="checkbox"]:hover + span {
    border: var(--checkbox-border-width) solid var(--checkbox-border-color-hover);
  }
  &__input[type="checkbox"]:checked:hover + span {
    border: var(--checkbox-border-width) solid var(--checkbox-border-color-checked-hover);
    background-color: var(--checkbox-background-color-checked-hover);
  }
  &__input[type="checkbox"]:checked:disabled + span {
    border: var(--checkbox-border-width) solid var(--checkbox-border-color-checked-disabled);
    background-color: var(--checkbox-background-color-checked-disabled);
  }
  &__input[type="checkbox"]:disabled + span {
    border: var(--checkbox-border-width) solid var(--checkbox-border-color-checked-disabled);
    background-color: var(--checkbox-background-color-disabled);
  }
  // &__input[type="checkbox"]:active + span {
  //   background-color: var(--color-dark-800);
  //   border: var(--border-width-s) solid var(--color-orange-800);
  // }
  // &__input[type="checkbox"]:focus + span {
  //   background-color: var(--color-dark-800);
  //   border: var(--border-width-s) solid var(--color-orange-800);
  // }
  // &__input[type="checkbox"]:focus + span {
  //   background-color: pink;
  // }
  &__content {
    display: inline-flex;
    align-self: flex-start;
  }
}
// .checkbox p {
//
// 	margin: 0;

// 	/* 3 Typography */
// 	font-family: $font-main;
// 	font-size: 14px;
// 	font-weight: 400;
// 	line-height: 22px;
// 	color: $color-textgray;

// 	/* 6 Sundry */
// 	cursor: pointer;
// }
// .checkbox input[type="checkbox"] {
//   position: absolute;

//   width: 1px;
//   height: 1px;
//   overflow: hidden;
//   clip: rect(0 0 0 0);
// }
// .checkbox input[type="checkbox"] + span {
//   position: relative;

//   box-sizing: border-box;
//   display: inline-flex;
//   width: var(--checkbox-size);
//   height: var(--checkbox-size);

//   background-color: var(--color-dark-300);
//   border: var(--border-width-s) solid var(--color-dark-200);
//   border-radius: var(--border-radius-4xs);

//   transition: all var(--transition);
// }
// .checkbox input[type="checkbox"]:checked + span {
//   border: var(--border-width-s) solid var(--color-orange-500);
//   background-color: var(--color-orange-500);
// }
// .checkbox input[type="checkbox"]:checked + span::before {
//   position: absolute;
//   top: 50%;
//   left: 50%;

//   display: block;
//   width: var(--checkbox-width-before);
//   height: var(--checkbox-height-before);

//   content: "";
//   background-image: url(../../../assets/icon/icon--checkbox-check.svg);
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   transform: translate(-50%, -50%);
// }
// .checkbox input[type="checkbox"]:hover + span {
//   background-color: var(--color-dark-800);
//   border: var(--border-width-s) solid var(--color-orange-800);
// }
// .checkbox input[type="checkbox"]:active + span {
//   background-color: var(--color-dark-800);
//   border: var(--border-width-s) solid var(--color-orange-800);
// }
// .checkbox input[type="checkbox"]:focus + span {
//   background-color: var(--color-dark-800);
//   border: var(--border-width-s) solid var(--color-orange-800);
// }
// .checkbox__text {
//   align-self: center;
// }
// .checkbox__img {
//   display: block;
//   width: var(--img-checkbox-width);
//   align-self: center;
//   margin-right: var(--space-2xs);
// }
// .checkbox input[type="checkbox"]:focus + span {
// 	/* 4 Visual */
// 	background-color: pink;
// }
// .checkbox input[type="checkbox"]:disabled + span {
// 	/* 4 Visual */
// 	cursor: not-allowed;
// 	border: 2px solid $color-border-2;
// 	background-color: $color-white;
// }

/* .checkbox input[type="checkbox"]:checked:focus + span::before */

/* .checkbox input[type="checkbox"]:checked:disabled + span::before */
