.list-chips {
  &__title {
    margin-bottom: var(--list-chips-mb);
  }
  &__body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--list-chips-body-gap);
  }
}
