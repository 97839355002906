@import "../../assets/sass/mixins.scss";
.menu {
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: var(--menu-list-row-gap);
    padding: 0;
    margin: 0;

    list-style: none;

    @include screen(xl) {
      row-gap: var(--menu-list-row-gap-xl);
    }
  }
  &__item {
    display: flex;
  }
  &__links {
    display: inline-flex;

    .icon {
      fill: var(--menu-icon-fill);

      transition: all var(--transition) ease 0s;
    }
    .text {
      color: var(--menu-text-color);

      transition: all var(--transition) ease 0s;
    }
    &:hover,
    &.active {
      .icon {
        fill: var(--menu-icon-fill-active);
      }
      .text {
        color: var(--menu-text-color-active);
      }
    }
  }
}
