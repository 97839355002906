@import "../../../assets/sass/mixins.scss";
.modal-confirm-action {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-3m);

    @include screen(md) {
      row-gap: var(--space-xl);
    }
  }
}
