@import"../../../assets/sass/mixins.scss";
.input-pin-code {
    box-sizing: border-box;
    width: 100%;
  
    &__password {
      position: relative;
      width: 100%;
  
      margin-bottom: var(--space-xs);
  
    }
    &__pin-code {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
  
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      row-gap: var(--space-m);
      padding: var(--space-4s) var(--space-m) var(--space-xl) var(--space-m);
  
      opacity: 0;
      visibility: hidden;
  
      background-color: var(--background-secondary);
      transition: all var(--transition) ease 0s;
  
      @include screen(lg) {
        position: absolute;
        top: 78px;
        left:  0;
        bottom: auto;
        right: auto;
  
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 240px;
        padding: var(--space-2l);
  
        border-radius: var(--border-radius-base);
        border: var(--border-width-base) solid var(--border-color-base);
      }
    }
    &__pin-code-mob-close {
      align-self: flex-end;
  
      @include screen(lg) {
        display: none;
      }
    }
    &__pin-code-mob-info {
      text-align: center;
  
      .input {
        width: 100%;
        max-width: 228px;
        margin: 0 auto; 
  
        &__input {
          padding: var(--space-2s) var(--space-3s);
  
          text-align: center;
        }
      }
      @include screen(lg) {
        display: none;
      }
    }
    &__pin-code-title {
      margin-bottom: var(--space-2xs);
    }
    &__pin-code-input-panel {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      column-gap: var(--space-xl);
      row-gap: var(--space-2l);
      max-width: 290px;
      margin: 0 auto;
  
      @include screen(lg) {
        gap: var(--space-2s);
      }
  
      .btn {
        padding: 0;
        width: 48px;
        height: 48px;
        
        @media(max-width: 991px) {
          border: none;
  
          .text {
            font-size: var(--font-size-32);
            line-height: var(--font-line-height-32);
            font-weight: 400;
          }
        }
  
        &:nth-child(10){
          .icon {
            fill: var(--color-green-mat);
          }
          &:hover {
            opacity: 0.6;
          }
          @include screen(lg) {
            border-color: var(--color-green-mat);
            background-color: var(--color-green-bg-mat);
          }
        }
        &:last-child {
          &:hover {
            opacity: 0.6;
          }
  
          @include screen(lg) {
            border-color: var(--color-red-mat);
          }
        }
      }
    }
    &__pin-code--open {
      overflow: auto;
  
      opacity: 1;
      visibility: visible;
  
      @include screen(lg) {
        overflow: visible;
      }
    }

  }