@import "../../../assets/sass/mixins.scss";
.switcher {
  display: flex;
  align-items: center;
  min-height: var(--switcher-height);

  border: var(--switcher-border-width) solid var(--switcher-border-color);
  background-color: var(--switcher-bg);
  border-radius: var(--switcher-border-radius);

  cursor: pointer;
  transition: all var(--transition) ease 0s;

  &:hover {
    border-color: var(--switcher-border-color-hover);
    background-color: var(--switcher-bg-hover);
  }

  &__button {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-self: normal;
    column-gap: var(--space-2xs);
    padding: var(--switcher-button-padding);
    margin: 0;
    width: 50%;

    color: var(--switcher-button-color);
    text-align: center;

    outline: none;
    background: none;
    cursor: pointer;

    background-color: transparent;

    transition: all var(--transition) ease 0s;

    @include screen(esm) {
      white-space: nowrap;
      word-break: keep-all;
    }

    .icon {
      flex: 0 0 var(--switcher-button-icon-size);
    }
    .text {
      @include screen(mobileLong) {
        font-size: var(--switcher-button-text-fz);
        line-height: var(--switcher-button-text-lh);
      }
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: block;
      width: 101%;
      height: 107%;

      background-color: var(--switcher-bg-active);
      border-radius: var(--switcher-border-radius);
      content: "";
      opacity: 0;
      transform: translate(1%, -3%);

      transition: all var(--transition) ease 0s;

      @include screen(md) {
        transform: translate(1%, -3%);
      }
    }
    &:hover {
      color: var(--switcher-button-color-hover);

      .icon {
        fill: var(--switcher-icon-fill-hover);
      }
      &::before {
        background-color: var(--switcher-hover-before-bg-color);
      }
    }

    & > * {
      position: relative;
    }

    .icon {
      fill: var(--switcher-icon-fill);
      transition: all var(--transition) ease 0s;
    }
  }
  &__button--active {
    color: var(--switcher-button-color-active);

    .icon {
      fill: var(--switcher-button-color-active);
    }

    &:hover {
      color: var(--switcher-button-active-color-hover);
      .icon {
        fill: var(--switcher-button-active-color-hover);
      }
    }

    &::before {
      opacity: 1;
    }
    &:first-child::before {
      transform: translate(-1%, -3%);
    }
    &:last-child::before {
      @include screen(md) {
        transform: translate(0, -3%);
      }
    }
  }
}
