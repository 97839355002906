@import "../../assets/sass/mixins.scss";
.body {
  padding-top: var(--header-height);

  background-color: var(--body-background-color);

  &__content {
    display: flex;
  }
}
