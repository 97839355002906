@import "../../assets/sass/mixins.scss";
.page {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: var(--space-m);
  padding-bottom: var(--space-m);

  @include screen(lg) {
    padding-top: var(--space-xl);
    padding-left: var(--space-2l);
    padding-bottom: var(--space-xl);
  }

  &__title {
    margin-bottom: var(--space-m);

    @include screen(lg) {
      margin-bottom: var(--space-2l);
    }
    .text--size-h1 {
      margin-bottom: var(--space-2s);
    }
  }
  &__body {
    display: flex;
    align-self: normal;
    flex: 1 1 auto;

    & > * {
      width: 100%;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: var(--space-xs);
    padding-top: var(--space-m);

    @include screen(md) {
      flex-direction: row;
      justify-content: space-between;
      padding-top: var(--space-xl);
    }
  }
}
