.icon {
  display: inline-flex;
  fill: var(--icon-fill-base);

  &--fill-none {
    fill: none;
  }
  &--fill-white {
    fill: var(--color-white);
  }
  &--stroke-none {
    stroke: none;
  }
  &--stroke-primary {
    stroke: var(--icon-stroke-base);
  }
  &--stroke-white {
    stroke: var(--color-white);
  }
  &--size-base {
    width: var(--icon-size-base);
    height: var(--icon-size-base);
  }
  &--size-sm {
    width: var(--icon-size-sm);
    height: var(--icon-size-sm);
  }
  &--size-md {
    width: var(--icon-size-md);
    height: var(--icon-size-md);
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-3s);
    padding: var(--space-m);
  }
  &__list-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: var(--space-s);

    font-family: var(--font-main);
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-16);
    color: var(--color-blue);
  }
}
