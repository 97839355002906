@import "../../assets/sass/mixins.scss";
.report-table {
  display: flex;
  flex-direction: column;
  row-gap: var(--report-table-row-gap);

  @include screen(md) {
    row-gap: 0;

    background-color: var(--report-table-background-color);
    border-radius: var(--report-table-border-radius);
  }

  &__head {
    display: none;
    @include screen(md) {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      column-gap: var(--space-xs);
      height: var(--report-table-head-height);
      padding: var(--report-table-padding-md);

      border-bottom: var(--report-table-border-width) solid var(--report-table-border-color);
    }
  }
  &__head-item:first-child,
  &__bottom-item:first-child,
  &__body-item-content:first-child,
  &__bottom-total-item:first-child {
    @include screen(md) {
      width: var(--report-table-item-width-1);
      flex: 0 0 var(--report-table-item-width-1);
    }
  }
  &__head-item:nth-child(2),
  &__bottom-item:nth-child(2),
  &__body-item-content:nth-child(2),
  &__bottom-total-item:nth-child(2) {
    @include screen(md) {
      width: var(--report-table-item-width-2);
      flex: 0 0 var(--report-table-item-width-2);
    }
  }
  &__head-item:nth-child(3),
  &__bottom-item:nth-child(3),
  &__body-item-content:nth-child(3),
  &__bottom-total-item:nth-child(3) {
    @include screen(md) {
      width: var(--report-table-item-width-3);
      flex: 0 0 var(--report-table-item-width-3);
    }
  }
  &__head-item:nth-child(4),
  &__bottom-item:nth-child(4),
  &__body-item-content:nth-child(4),
  &__bottom-total-item:nth-child(4) {
    @include screen(md) {
      width: var(--report-table-item-width-4);
      flex: 0 0 var(--report-table-item-width-4);
    }
  }
  &__head-item:last-child,
  &__bottom-item:last-child,
  &__body-item-content:last-child,
  &__bottom-total-item:last-child {
    @include screen(md) {
      width: var(--report-table-item-width-last);
      flex: 1 1 var(--report-table-item-width-last);
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    row-gap: var(--report-table-body-row-gap);
  }
  &__title {
    @include screen(md) {
      display: none;
    }
  }
  &__body-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: var(--space-xs);
    box-sizing: border-box;
    padding: var(--report-table-padding);

    background-color: var(--report-table-background-color);
    border-radius: var(--report-table-border-radius-all);

    @include screen(md) {
      flex-direction: row;
      column-gap: var(--space-xs);
      padding: var(--report-table-padding-md);
      height: var(--report-table-body-item-height);

      background-color: transparent;
      border-radius: 0;
      border-bottom: var(--report-table-border-width) solid var(--report-table-border-color);
    }
  }
  &__body-item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--space-xs);
    flex: 1 1 auto;
    align-self: normal;

    .text {
      overflow: hidden;

      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__bottom,
  &__bottom-total {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
    padding: var(--report-table-padding);

    background-color: var(--report-table-bottom-background-color);

    border-radius: var(--report-table-border-radius-all);

    @include screen(md) {
      flex-direction: row;
      align-items: center;
      height: var(--report-table-bottom-height);
      padding: var(--report-table-padding-md);
      column-gap: var(--space-xs);

      border-radius: var(--report-table-border-radius-bottom);
    }
  }
  &__bottom-total {
    @include screen(md) {
      border-radius: 0;

      border-bottom: var(--report-table-border-width) solid var(--report-table-bottom-total-border-color);
    }
  }
  &__bottom-item,
  &__bottom-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--space-xs);

    @include screen(md) {
      justify-content: flex-start;
      column-gap: 0;
    }
    &:first-child {
      .report-table__title {
        display: block;
      }
    }
  }
}
