@import "../../assets/sass/mixins.scss";
.list-info {
  .text--size-sub-title-modal {
    margin-bottom: var(--space-3s);

    @include screen(md) {
      margin-bottom: var(--space-4m);
    }
  }
  &__body {
    padding: 0;
    margin: 0;

    list-style: none;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: var(--space-2xs);
    padding-top: var(--space-4s);
    padding-bottom: var(--space-4s);

    border-bottom: var(--list-info-item-border);

    &:first-child {
      border-top: var(--list-info-item-border);
      border-bottom: var(--list-info-item-border);
    }
    .text:last-child {
      text-align: right;
    }
  }
}
