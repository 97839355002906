@import "../../../../assets/sass/mixins.scss";
.list-bids {
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--chat-list-bids-height));
  padding-right: 5px;
  overflow: auto;

  cursor: pointer;

  scrollbar-width: thin;
  scrollbar-color: var(--color-blue-mat) var(--color-button-border-default);

  &::-webkit-scrollbar {
    width: 3px;
    height: 80%;
  }
  &::-webkit-scrollbar-track {
    background: var(--color-button-border-default);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--color-blue-mat);
    border: 1px solid var(--color-blue-mat);
  }
  @include screen(md) {
    padding-right: 0;
    height: var(--chat-list-bids-height-md);
  }

  &__item {
    display: flex;
    align-items: center;
    align-self: normal;
    padding-top: var(--chat-list-bids-pd);
    padding-bottom: var(--chat-list-bids-pd);
    padding-left: var(--space-2s);

    border-bottom: var(--chat-border-width) solid var(--chat-border-color);
  }
  &__info {
    flex: 1 1 auto;
  }
  &__item-icon {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--chat-list-bids-icon-size);
    height: var(--chat-list-bids-icon-size);
    flex: 0 0 var(--chat-list-bids-icon-size);

    border-radius: 50%;
    background-color: var(--color-disable-background);

    .icon {
      fill: var(--color-button-text-secondary);
    }
  }
  &__item-body {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    column-gap: var(--space-2s);
  }
  &__info-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: var(--space-2xs);
  }
  &__item-date {
    @include screen(md) {
      display: none;
    }
    .text {
      font-weight: 400;
    }
  }
  &__item-status {
    .text {
      font-weight: 400;
    }

    &--paid {
      color: var(--color-green-mat);
    }
    &--not-paid {
      color: var(--color-red-mat);
    }
  }
  &__item-number-messages {
    position: absolute;
    top: var(--chat-list-bids-item-number-postition-top);
    right: var(--chat-list-bids-item-number-postition-right);
  }
  &__item--active {
    border-left: var(--border-width-m) solid var(--color-blue);

    @include screen(md) {
      background-color: var(--color-disable-background);
    }
  }
}
