.select-custom {
  position: relative;
  width: 100%;

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: var(--input-label-height);
    column-gap: var(--space-2xs);
    box-sizing: border-box;
    padding-bottom: 4px;
  }
  &__icon {
    display: flex;
  }
  &__noti {
    display: flex;
    padding-top: 5px;
    box-sizing: border-box;
    min-height: var(--input-notification-height);
  }
  &__control {
    align-items: center;
    box-sizing: border-box;
    padding: var(--select-custom-control-pd);
    height: var(--select-custom-control-height) !important;

    cursor: pointer !important;
    border-radius: var(--select-custom-border-radius) !important;
    border-color: var(--select-custom-border-color) !important;
    box-shadow: none !important;

    &:hover {
      border-color: var(--color-button-text-secondary) !important;
    }
  }
  &__input-container {
    padding: 0 !important;
    margin: 0 !important;

    color: var(--select-custom-text-color) !important;
    border: none !important;
  }
  &__value-container {
    padding: 0 !important;

    font-size: var(--select-custom-font-size);
    line-height: var(--select-custom-line-height);
    color: var(--select-custom-text-color) !important;
  }
  &__single-value {
    display: flex;
    margin: 0 !important;
  }
  &__indicator {
    padding: 0 0 0 var(--select-custom-indicator-pd) !important;

    cursor: pointer !important;

    .icon {
      fill: var(--select-custom-indicator-icon-fill);
      transition: all var(--transition) ease 0s;

      &:hover {
        fill: var(--select-custom-indicator-icon-fill-hover);
      }
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__menu {
    margin: 0 !important;
    z-index: 3 !important;

    border: var(--select-custom-border-width) solid var(--select-custom-border-color-active) !important;
    border-radius: 0 0 var(--select-custom-border-radius) var(--select-custom-border-radius) !important;
    border-top: none !important;
    box-shadow: none !important;
  }
  &__menu-list {
    min-height: var(--select-custom-menu-list-min-height);
    max-height: var(--select-custom-menu-list-max-height) !important;
    padding: 0 !important;
  }
  &__option {
    position: relative;

    display: flex !important;
    padding: var(--select-custom-option-pd) !important;

    font-size: var(--select-custom-font-size) !important;
    line-height: var(--select-custom-line-height) !important;
    color: var(--select-custom-text-color) !important;

    cursor: pointer !important;
    border-radius: 0 !important;

    &:hover {
      background-color: var(--langs-option-bg-color-hover) !important;
    }
    &:last-child {
      border-radius: 0 0 var(--select-custom-border-radius) var(--select-custom-border-radius) !important;
    }
  }
  &__option--is-selected {
    background-color: transparent !important;

    &::before {
      position: absolute;
      top: 48%;
      right: 12px;

      display: flex;
      width: var(--langs-option-check-size);
      height: var(--langs-option-check-size);

      content: "";
      background-image: url(../../../assets/icon/icon--check-grey.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      transform: translateY(-50%);
    }
  }
  &__control--menu-is-open {
    border-radius: var(--select-custom-border-radius) var(--select-custom-border-radius) 0 0 !important;
    border-bottom: none !important;
    border-color: var(--select-custom-border-color-active) !important;

    background-color: var(--select-custom-bg-color) !important;

    &:hover {
      border-color: var(--select-custom-border-color-active) !important;
    }
  }
  &__option--is-focused {
    background-color: transparent !important;
  }
  .icon-img {
    border-radius: 50%;
  }
}
