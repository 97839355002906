@import "../../assets/sass/mixins.scss";
.news {
  position: relative;
  display: flex;
  box-sizing: border-box;
  margin-bottom: var(--space-m);

  &__slider {
    padding-bottom: var(--space-4m) !important;
    width: 100%;
  }
  &__item {
    padding: var(--news-item-pd);

    background-color: var(--news-item-bg-color);
    border: var(--news-item-border-width) solid var(--news-item-border-color);
    border-radius: var(--news-item-border-r);

    @include screen(md) {
      padding: var(--news-item-pd-md);
    }
  }
  &__button-close {
    position: absolute;
    top: 10px;
    right: 4px;

    @include screen(md) {
      right: 10px;
    }
  }
  &__body {
    flex: 1 1 auto;
    min-height: var(--news-body-height);
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    transition: all var(--transition);

    &::after {
      display: none;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .swiper-button-prev {
    left: 8px;

    background-image: url("../../assets/images/img-icon-arrow-chevron-left.svg");

    @include screen(md) {
      left: 14px;
    }
  }
  .swiper-button-next {
    right: 8px;

    background-image: url("../../assets/images/img-icon-arrow-chevron-right.svg");

    @include screen(md) {
      right: 14px;
    }
  }
  .swiper-pagination {
    bottom: 0;
  }
}
