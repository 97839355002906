.btn--icon-outline {
  padding: var(--button-icon-padding);
  height: auto;

  color: var(--button-outline-color);

  border: var(--button-border-width) solid var(--button-outline-border-color);
  background: none;

  & .icon {
    fill: var(--button-outline-icon-fill);
  }

  &:hover {
    color: var(--button-outline-color-hover);

    border: var(--button-border-width) solid var(--button-outline-border-color-hover);
    background: none;

    & .icon {
      fill: var(--button-outline-icon-fill-hover);
    }
  }
  &:active {
    color: var(--button-outline-color-active);

    border: var(--button-border-width) solid var(--button-outline-border-color-active);
    background: none;

    & .icon {
      fill: var(--button-outline-icon-fill-active);
    }
  }
  &:disabled {
    color: var(--button-outline-color-disable);

    border: var(--button-border-width) solid var(--button-outline-border-color-disable);
    background: var(--button-outline-background-disabled);

    & .icon {
      fill: var(--button-outline-icon-fill-disable);
    }
  }
}
