@import "../../../assets/sass/mixins.scss";
.chips {
  display: inline-flex;
  padding: var(--chips-padding);
  box-sizing: border-box;

  color: var(--chips-color);

  border: var(--chips-border-width) solid var(--chips-border-color);
  border-radius: var(--chips-border-radius);
  cursor: pointer;

  transition: all var(--transition) ease 0s;

  @include screen(lg) {
    padding: var(--chips-padding-lg);
  }

  .text {
    white-space: nowrap;
    word-break: keep-all;
  }

  &:hover {
    color: var(--chips-color-hover);
    border-color: var(--chips-border-color-hover);
  }

  &--active {
    color: var(--chips-color-active);
    border-color: var(--chips-border-color-active);
    background-color: var(--chips-bg-active);
  }
  &--disable {
    color: var(--chips-color-disable);
    border-color: var(--chips-border-color-disable);
    background-color: var(--chips-bg-disable);

    cursor: not-allowed;
    &:hover {
      color: var(--chips-color-disable);
      border-color: var(--chips-border-color-disable);
    }
  }
}
