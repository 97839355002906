@import "../../../assets/sass/mixins.scss";
.tabs {
  position: relative;

  width: 100%;
}
.tabs-list {
  display: flex;
  align-items: flex-start;
  column-gap: var(--tabs-list-column-gap);
  padding-right: 100px;

  @include screen(lg) {
    column-gap: var(--tabs-list-column-gap-lg);
    padding-right: 0;

    overflow: visible;
  }
}
.tab {
  position: relative;
  padding-bottom: var(--tab-pb);

  color: var(--tab-color);

  cursor: pointer;

  .text {
    display: inline-flex;
    column-gap: var(--tab-text-column-gap);

    white-space: nowrap;
    word-break: keep-all;

    transition: all var(--transition) ease 0s;
  }

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    display: block;
    width: 0;
    height: var(--tab-before-height);

    background-color: var(--tab-before-background-color);
    content: "";

    transition: all var(--transition) ease 0s;
  }
  &:hover {
    .text {
      color: var(--tab-color-hover);
    }
  }
  &--active {
    color: var(--tab-color-active);

    &::before {
      width: 100%;
    }
  }
  &--disable {
    color: var(--tab-color-disable);
    cursor: not-allowed;
    &:hover {
      .text {
        color: var(--tab-color-disable);
      }
    }
  }
}
.tab-panel {
  display: none;

  &--selected {
    display: block;
  }
}
.tabs-header-select {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: var(--space-m);
  margin-bottom: var(--space-2s);
  padding-bottom: var(--space-2xs);
  overflow-x: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--color-blue-mat) var( --color-button-border-default);

  &::-webkit-scrollbar { 
    width: 50%; height: 6px; 
  }
  &::-webkit-scrollbar-track { 
    background: var(--color-button-border-default); 
  }
  &::-webkit-scrollbar-thumb {
     background: var(--color-blue-mat); 
     border: 1px solid var(--color-blue-mat); 
  }

  @include screen(lg) {
    padding: var(--tabs-header-select-padding-lg);
    margin-bottom: var(--space-3m);

    background-color: var(--tabs-header-select-background-color);
    border-radius: var(--tabs-header-select-border-radius);

    overflow: inherit;
  }

  .dropdown-quantity {
    position: absolute;
    top: -15px;
    right: 0;
    z-index: 10;

    @include screen(lg) {
      position: static;
    }

    & > .text {
      display: none;

      @include screen(lg) {
        display: block;
      }
    }
  }
}