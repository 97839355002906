@import "../../../assets/sass/mixins.scss";
.pagination {
  display: flex;
  align-items: center;
  column-gap: var(--pagination-column-gap);

  @include screen(md) {
    column-gap: var(--pagination-column-gap-md);
  }
  &__number {
    cursor: pointer;

    .text {
      transition: all var(--transition) ease 0s;
    }

    &:hover {
      .text {
        color: var(--pagination-color-hover);
      }
    }
  }
  &__number--current {
    .text {
      color: var(--pagination-current-color);
    }
  }
}
