@import "../../../../assets/sass/mixins.scss";
/* Size */
.text--size-h1 {
  font-family: var(--font-main);
  font-size: var(--font-size-24);
  line-height: var(--font-line-height-32);
  font-weight: 600;

  @include screen(lg) {
    font-size: var(--font-size-32);
    line-height: var(--font-line-height-36);
  }
}
.text--size-h2 {
  font-family: var(--font-main);
  font-size: var(--font-size-18);
  line-height: var(--font-line-height-24);
  font-weight: 600;

  @include screen(lg) {
    font-size: var(--font-size-20);
  }
}
.text--size-h3 {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: 600;
}
.text--size-title-modal {
  font-family: var(--font-main);
  font-size: var(--font-size-24);
  line-height: var(--font-line-height-32);
  font-weight: 600;

  @include screen(lg) {
    font-size: var(--font-size-28);
    line-height: var(--font-line-height-34);
  }
}
.text--size-sub-title-modal {
  font-family: var(--font-main);
  font-size: var(--font-size-20);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}

.text--size-button {
  display: inline-flex;

  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-menu {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-body-small {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-18);
  font-weight: 600;
}
.text--size-body {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-body-light {
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 400;
}
.text--size-table {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 400;

  @include screen(lg) {
    font-size: var(--font-size-16);
  }
}
.text--size-title-chat {
  font-family: var(--font-main);
  font-size: var(--font-size-13);
  line-height: var(--font-line-height-20);
  font-weight: 400;
}
.text--size-chips {
  display: inline-flex;

  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-tab {
  display: inline-flex;

  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-label-input {
  display: inline-flex;

  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-16);
  font-weight: 600;
}
.text--size-notification-input {
  display: inline-flex;

  font-family: var(--font-main);
  font-size: var(--font-size-13);
  line-height: var(--font-line-height-16);
  font-weight: 400;
}
.text--size-tooltip {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-20);
  font-weight: 400;
}
.text--size-checkbox-label {
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 400;
}
.text--size-status {
  display: inline-flex;
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 600;
}
.text--size-title-card {
  display: inline-flex;
  font-family: var(--font-main);
  font-size: var(--font-size-16);
  line-height: var(--font-line-height-24);
  font-weight: 600;

  @include screen(lg) {
    font-size: var(--font-size-20);
  }
}
.text--size-card {
  display: inline-flex;
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 400;

  @include screen(lg) {
    font-size: var(--font-size-16);
  }
}
.text--size-card-bold {
  display: inline-flex;
  font-family: var(--font-main);
  font-size: var(--font-size-14);
  line-height: var(--font-line-height-24);
  font-weight: 600;

  @include screen(lg) {
    font-size: var(--font-size-16);
  }
}
