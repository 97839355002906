@import "../../../../assets/sass/mixins.scss";
.page-history-operation-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-s);

  & > div:last-child {
    display: none;

    @include screen(md) {
      display: flex;
    }
  }
}
