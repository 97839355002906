@import "../../../assets/sass/mixins.scss";
.text-area {
  position: relative;

  width: 100%;

  &__label {
    display: flex;
    min-height: var(--input-label-height);
    box-sizing: border-box;
    padding-bottom: 4px;
  }
  &__area {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    min-height: var(--text-area-height);
    padding: var(--text-area-pd);
    font-family: var(--font-main);
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-18);
    color: var(--color-text);
    background: var(--chat-bg-color);
    border-radius: var(--text-area-border-radius);
    border: var(--text-area-border-width) solid var(--text-area-border-color);
    outline: none;
    resize: vertical;

    @include screen(md) {
      font-size: var(--font-size-16);
      line-height: var(--font-line-height-24);
    }
  }
  &__noti {
    display: flex;
    padding-top: 5px;
    box-sizing: border-box;
    min-height: var(--input-notification-height);
  }
}
