.counter-round {
  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--counter-round-size);
  height: var(--counter-round-size);
  flex: 0 0 var(--counter-round-size);

  font-family: var(--counter-round-font-family);
  font-size: var(--counter-round-font-size);
  line-height: var(--counter-round-line-height);
  font-weight: var(--counter-round-font-weight);
  color: var(--counter-round-color);

  border-radius: 50%;
  background-color: var(--counter-round-bg-color);
}
