.btn--block {
  width: 100%;
}
.btn--sm {
  height: var(--button-size-sm);
  padding: var(--button-padding-sm);
}
.btn--md {
  height: var(--button-size-md);
  padding: var(--button-padding-md);

  @include screen(md) {
    height: var(--button-size-md);
    padding: var(--button-padding-md);
  }
}
.btn--sm.btn--icon-outline {
  height: var(--button-size-sm);
  width: var(--button-size-sm);
  padding: 0;
}
.btn--md.btn--icon-outline {
  height: var(--button-size-md);
  width: var(--button-size-md);
  padding: 0;
}
