.btn--flat-secondary {
  height: auto;
  padding: 0;

  color: var(--button-flat-secondary-color);

  border: none;
  background: none;

  .icon {
    fill: var(--button-flat-secondary-icon-fill);
  }

  &:hover {
    color: var(--button-flat-secondary-color-hover);
    border: none;
    background: none;

    .icon {
      fill: var(--button-flat-secondary-icon-fill-hover);
    }
  }
  &:active {
    color: var(--button-flat-secondary-color-active);
    border: none;
    background: none;

    .icon {
      fill: var(--button-flat-secondary-icon-fill-active);
    }
  }
  &:disabled {
    color: var(--button-flat-secondary-color-disabled);
    border: none;
    background: none;

    .icon {
      fill: var(--button-flat-secondary-icon-fill-disabled);
    }
  }
}
