@import "../../../../assets/sass/mixins.scss";
.chat-text-area {
  position: relative;
  column-gap: var(--space-2xs);

  display: flex;

  &__body {
    position: relative;
    flex: 1 1 auto;
  }
  &__area {
    display: flex;
    box-sizing: border-box;
    overflow: scroll;
    width: 100%;
    height: var(--chat-text-area-height);
    padding: var(--chat-text-area-pd);

    font-family: var(--font-main);
    font-size: var(--font-size-14);
    line-height: var(--font-line-height-18);
    color: var(--color-text);

    background: var(--chat-bg-color);
    border-radius: var(--chat-text-area-border-radius);
    border: var(--chat-border-width) solid var(--chat-border-color);
    outline: none;
    resize: none;

    @include screen(md) {
      font-size: var(--font-size-16);
      line-height: var(--font-line-height-24);
    }
  }

  &__button {
    position: absolute;
    bottom: 8px;
    right: 8px;

    .btn {
      width: 36px;
      height: 36px;

      border-radius: 50%;

      .icon {
        fill: var(--color-button-text-secondary);
      }

      &:hover {
        background-color: var(--color-disable-background);

        .icon {
          fill: var(--color-blue);
        }
      }
    }
  }
}
