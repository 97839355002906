.icon-text {
  display: inline-flex;

  &--gap-esm {
    gap: var(--icon-text-gap-esm);
  }
  &--gap-sm {
    gap: var(--icon-text-gap-sm);
  }
  &--gap-m {
    gap: var(--icon-text-gap-m);
  }
  &--gap-lg {
    gap: var(--icon-text-gap-lg);
  }
  &--gap-xl {
    gap: var(--icon-text-gap-xl);
  }
  &--gap-2xl {
    gap: var(--icon-text-gap-2xl);
  }
  &--align-start {
    align-items: flex-start;
  }
  &--align-end {
    align-items: flex-end;
  }
  &--align-center {
    align-items: center;
  }
  &--align-normal {
    align-items: normal;
  }
  &--size-base {
    .icon {
      flex: 0 0 var(--icon-size-base);
    }
  }
  &--size-sm {
    .icon {
      flex: 0 0 var(--icon-size-sm);
    }
  }
  &--size-md {
    .icon {
      flex: 0 0 var(--icon-size-md);
    }
  }
  &--size-normal {
    .icon {
      flex: 0 1 auto;
    }
  }
  &__item-number-messages{
    position: relative;
    top: 15%;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 2px;
    min-width: var(--chat-list-bids-item-number-messages-size);
    height: var(--chat-list-bids-item-number-messages-size);

    font-size: var(--chat-list-bids-item-number-messages-font-size);
    line-height: var(--chat-list-bids-item-number-messages-line-height);
    color: var(--chat-list-bids-item-number-messages-color);

    background-color: var(--chat-list-bids-item-number-messages-bg-color);
    border-radius: var(--chat-list-bids-item-number-messages-border-radius);

  }
}
