@import "../../assets/sass/mixins.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1010;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: var(--header-height);
  row-gap: var(--space-3s);

  border-bottom: var(--header-border-width) solid var(--header-border-color);
  background-color: var(--header-background-color);

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__logo {
    display: flex;
    align-items: center;
    column-gap: var(--space-3s);

    .logo {
      @include screen(xl) {
        margin-right: 117px;
      }
    }
  }
  &__langs {
    display: flex;

    .langs__value-container {
      flex: 0 0 24px !important;

      @include screen(md) {
        flex: 0 0 auto !important;
      }
    }
    .langs__control {
      padding-left: 10px;
      padding-right: 10px;

      @include screen(esm) {
        padding-left: 0;
        padding-right: 6px;
      }
      @include screen(lg) {
        padding-left: 20px;
        padding-right: 0;
      }
    }
    .icon-text {
      .text {
        display: none;

        @include screen(md) {
          display: block;
        }
      }
    }
  }
  &__base-currency {
    display: flex;
  }
  &__right {
    display: flex;
    column-gap: var(--space-2s);

    @include screen(lg) {
      flex: 1 1 auto;
    }
  }
  &__actions {
    position: fixed;
    top: var(--header-height);
    left: -1000px;
    right: 0;

    display: flex;
    width: 100%;
    padding: var(--space-m);

    background-color: var(--header-background-color);

    transition: all var(--transition);

    box-shadow: var(--shadow-one);

    @include screen(lg) {
      position: static;

      padding: 0;

      box-shadow: none;
    }

    &--open {
      left: 0;
    }
  }
  &__actions-body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    column-gap: var(--space-s);
    row-gap: var(--space-4s);

    @include screen(sm) {
      max-width: var(--container-width-media-sm);
      padding-left: var(--bases-gutter-mob-x);
      padding-right: var(--bases-gutter-mob-x);
    }
    @include screen(md) {
      max-width: var(--container-width-media-md);
    }
    @include screen(lg) {
      justify-content: space-between;
      max-width: 100%;
      padding: 0;
    }
  }
  &__actions-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    row-gap: var(--space-4s);
    width: 100%;

    @include screen(esm) {
      justify-content: space-between;
    }
    @include screen(sm) {
      width: auto;
      column-gap: var(--space-s);
    }
  }
  &__actions-choice {
    display: flex;
    align-items: center;
  }
  &__actions-afk {
    flex: 1 1 auto;

    @include screen(esm) {
      flex: 0 1 auto;
    }

    .btn {
      width: 100%;

      @include screen(esm) {
        width: auto;
      }
    }
  }
  &__actions-user {
    display: flex;
    align-items: center;
    column-gap: var(--space-s);
    flex: 1 1 auto;

    @include screen(sm) {
      flex: 0 1 auto;
    }

    .btn {
      width: 100%;

      @include screen(sm) {
        width: auto;
      }
    }
  }
  &__user {
    @include screen(lg) {
      display: none;
    }
    .btn {
      width: 38px;
      flex: 0 0 38px;
      padding-left: 0;
      padding-right: 0;

      border-radius: 50%;

      @include screen(lg) {
        width: auto;
        padding: var(--button-padding-md);

        border-radius: var(--button-border-radius);
      }
      .text {
        display: none;

        @include screen(md) {
          display: block;
        }
      }
    }
  }
  .hamburger {
    @include screen(xl) {
      display: none;
    }
  }
  .icon-text {
    .icon-img {
      border-radius: 50%;
    }
  }
}
